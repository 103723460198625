import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueClipboard from 'vue-clipboard2'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faLink,
  faEdit,
  faCheck,
  faCheckCircle,
  faTimes,
  faPlus,
  faMinus,
  faExternalLinkAlt,
  faHistory,
  faBullseye,
  faArrowLeft,
  faRedo,
  faDownload,
  faPen,
  faTrash,
  faImage
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Auth0Plugin } from '@/plugins/vue_auth'
import mixins from '@/mixins/global'
import { tokenSwap } from '@/utils/accessToken'
import Cookies from 'js-cookie'
import { InlineSvgPlugin } from 'vue-inline-svg'
import Urlbox from 'urlbox'
import Fragment from 'vue-fragment'
import * as Sentry from '@sentry/vue'
import { bus } from './utils/event-bus'
import Alert from '@/components/global/alert/Alert'

require('dotenv').config()

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    tracesSampleRate: 1.0,
    replaySessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

Vue.config.productionTip = false
Vue.use(require('vue-moment'))
Vue.use(VueClipboard)
Vue.use(mixins)
Vue.use(InlineSvgPlugin)
Vue.use(Fragment.Plugin)

let arcClient = null
let outreachClient = null

const createApiClients = () => {
  if (!Cookies.get('_.arc_score.arcScoreKey') && !Cookies.get('_.arc_score.salesOutreachKey')) {
    tokenSwap()
  } else {
    if (Cookies.get('_.arc_score.arcScoreKey') && !arcClient) {
      if (process.env.NODE_ENV === 'development') {
        Cookies.set('_.arc_score.arcScoreKey', process.env.VUE_APP_ARC_SCORE_API_KEY)
      }
      arcClient = axios.create({
        baseURL: process.env.VUE_APP_ARC_SCORE_API,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(Cookies.get('_.arc_score.arcScoreKey') + ':')}`
        }
      })
    }

    if ((process.env.NODE_ENV !== 'everservice' || process.env.NODE_ENV !== 'health') && Cookies.get('_.arc_score.salesOutreachKey') && !outreachClient) {
      outreachClient = axios.create({
        baseURL: process.env.VUE_APP_SALES_OUTREACH_API,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${Cookies.get('_.arc_score.salesOutreachKey')}`
        }
      })
    }
  }

  Vue.prototype.$arc = arcClient
  outreachClient && (Vue.prototype.$outreach = outreachClient)
  store.commit('auth/setOutreachKey', !!Cookies.get('_.arc_score.salesOutreachKey'))
}

createApiClients()

Vue.use(Auth0Plugin, {
  domain: `${process.env.VUE_APP_AUTH0_DOMAIN}`,
  clientId: `${process.env.VUE_APP_AUTH0_CLIENT_ID}`,
  onRedirectCallback: (state) => {
    createApiClients()
    router.push(
      state && state.targetUrl
        ? state.targetUrl
        : window.location.pathname
    )
  },
  cacheLocation: 'localstorage'
})

bus.$on('token-swap', () => {
  createApiClients()
})

const urlbox = Urlbox(process.env.VUE_APP_URL_BOX_API_PUBLIC_KEY, process.env.VUE_APP_URL_BOX_API_PRIVATE_KEY)

const createUrlBoxImage = async (url, options) => {
  if (url) {
    const imgUrl = urlbox.buildUrl({
      url: url,
      format: 'jpg',
      ...options
    })
    return imgUrl
  }
}

Vue.prototype.$urlbox = createUrlBoxImage
Vue.prototype.$axios = axios

library.add(
  faLink,
  faEdit,
  faCheck,
  faCheckCircle,
  faTimes,
  faPlus,
  faMinus,
  faExternalLinkAlt,
  faHistory,
  faBullseye,
  faArrowLeft,
  faRedo,
  faDownload,
  faPen,
  faTrash,
  faImage
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('alert', Alert)

new Vue({
  router,
  store,
  render: h => h(App),
  computed: {
    baseUrl () {
      return process.env.VUE_APP_BASE_URL
    }
  },
  mounted () {
    bus.$on('user:create:failed', () => {
      alert('Something went wrong, please contact support.')
      Cookies.remove('_.arc_score.arcScoreKey')
      Cookies.remove('_.arc_score.salesOutreachKey')
      Cookies.remove('_.arc_score.arcScoreViewKey')
      store.dispatch('auth/setProfile', null)
      store.dispatch('form/clearData')
      this.$auth.logout({
        logoutParams: {
          returnTo: this.baseUrl
        }
      })
    })
  },
  beforeDestroy () {
    bus.$off('user:create:failed')
  }
}).$mount('#app')
