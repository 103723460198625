<template>
  <BaseLayout
    title="Agency Scores"
    :customClasses="['agencies']"
    ref="baseLayout">
    <Form
      @submit.prevent="filterResults">
      <strong>Filters</strong>
      <Input
        placeholder="Name"
        :hasLabel="true"
        labelText="Name"
        v-model="form.agencyName"
        :disabled="loading" />
      <Input
        placeholder="Url"
        :hasLabel="true"
        labelText="Url"
        v-model="form.agencyUrl"
        :disabled="loading" />
      <Input
        placeholder="5"
        type="number"
        :hasLabel="true"
        labelText="Minimum # of sites"
        v-model="form.sitesMin"
        :disabled="loading" />
      <div class="flex">
        <label>Arc Score Range</label>
        <Input
          placeholder="Min"
          type="number"
          v-model="form.arcScoreMin"
          :disabled="loading" />
        <Input
          placeholder="Max"
          type="number"
          v-model="form.arcScoreMax"
          :disabled="loading" />
      </div>
      <Dropdown
        dropdownPlaceholder="5"
        :inputHasLabel="true"
        inputLabelText="Tier Maximum"
        :hasIcon="true"
        :options="[
          1,
          2,
          3,
          4,
          5
        ]"
        ref="filterDropdown1"
        @select="form.tierMax = $event"
        :isDisabled="loading" />
      <Button
        type="submit"
        text="Apply"
        :isDisabled="loading" />
    </Form>
    <action-links
        textAlign="right"
        v-if="!loading">
      <Button
        @click="clearFilters"
        text="Clear filters" />
    </action-links>
    <Loading
      v-show="loading"
      text="Loading agencies" />
    <Table
      :loading="loading"
      v-if="sortedResults.length > 0">
      <template slot="head">
        <t-row>
          <t-heading
            @click="sortBy('name')"
            :class="sortedClass('name')">
            Name
          </t-heading>
          <t-heading
            @click="sortBy('url')"
            :class="sortedClass('url')">
            Url
          </t-heading>
          <t-heading
            @click="sortBy('domain_count')"
            :class="sortedClass('domain_count')">
            # of Sites
            </t-heading>
          <t-heading
            @click="sortBy('avg_arc_score')"
            :class="sortedClass('avg_arc_score')">
            Avg. Arc Score
          </t-heading>
          <t-heading
            @click="sortBy('avg_market_tier')"
            :class="sortedClass('avg_market_tier')">
            Tier Average
          </t-heading>
          <t-heading></t-heading>
        </t-row>
      </template>
      <template slot="body">
        <t-row
          v-for="(result, i) in sortedResults"
          :key="i">
          <t-cell>
            <div class="logo-container">
              <img height="24" width="24" :src="result.favicon_url || result.icon_url || require('../../assets/img/fallback_fav.png')"/>{{ result.name }}
            </div>
          </t-cell>
          <t-cell>
            <a
              :href="result.url"
              target="_blank"
              rel="noopener"
              v-if="result.url">
              {{ result.url }}
            </a>
            <span v-else>N/A</span>
          </t-cell>
          <t-cell>{{ result.domain_count }}</t-cell>
          <t-cell>{{ result.avg_arc_score }}</t-cell>
          <t-cell>{{ result.avg_market_tier }}</t-cell>
          <t-cell>
            <Button
              :hasLink="true"
              :btnLink="{
                name: 'agency',
                query: {
                  id: result.id
                }
              }"
              :isView="true" />
          </t-cell>
        </t-row>
      </template>
    </Table>
    <p v-else-if="!loading && sortedResults.length === 0">
      No results. Try changing or clearing filters.
    </p>
    <Pagination
      v-if="!loading"
      :perPage="pagination.per_page"
      :currentPage="pagination.page"
      :nextNum="pagination.next_num"
      :prevNum="pagination.prev_num"
      :totalItems="filteredResults.marketing_companies.length"
      :isComputed="true"
      @pageChanged="changePage($event)" />
  </BaseLayout>
</template>

<script>
import Table from '@/components/table/Table'
import TableRow from '@/components/table/TableRow'
import TableDataCell from '@/components/table/TableDataCell'
import TableHeading from '@/components/table/TableHeading'
import BaseLayout from '@/views/BaseLayout'
import Button from '@/components/form/Button'
import Loading from '@/components/misc/Loading'
import ActionLinks from '@/components/misc/ActionLinks'
import Form from '@/components/form/Form'
import Input from '@/components/form/Input'
import Dropdown from '@/components/form/Dropdown'
import Pagination from '@/components/misc/Pagination'
import _ from 'lodash'
import { mapState } from 'vuex'
import { bus } from '@/utils/event-bus.js'

export default {
  name: 'ScoreAgencyList',
  components: {
    Table,
    Loading,
    Button,
    Form,
    Input,
    BaseLayout,
    Dropdown,
    Pagination,
    't-row': TableRow,
    't-cell': TableDataCell,
    't-heading': TableHeading,
    'action-links': ActionLinks
  },
  data () {
    return {
      loading: false,
      form: {
        sitesMin: '',
        arcScoreMin: '',
        arcScoreMax: '',
        tierMax: '',
        agencyName: '',
        agencyUrl: '',
        dropdownOptions: [20, 50, 100, 250, 500, 1000]
      },
      marketingCompanies: [],
      filteredResults: {
        marketing_companies: []
      },
      sort: {
        key: 'domain_count',
        isAsc: false
      },
      pagination: {
        page: 1,
        next_num: 2,
        prev_num: null,
        per_page: 500,
        total: 0
      }
    }
  },
  computed: {
    ...mapState({
      formData: state => state.form
    }),

    sortedResults () {
      var list = this.filteredResults.marketing_companies.length > 0 ? this.filteredResults.marketing_companies : []
      if (this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        })
      }
      const nonBlacklisted = list.filter(item => !this.isBlacklisted(item.url))
      return nonBlacklisted.slice((this.pagination.page - 1) * this.pagination.per_page, this.pagination.page * this.pagination.per_page)
    }
  },
  mounted () {
    bus.$on('token-swap', () => {
      this.getMarketingCompanies()
    })
    if (this.$arc) {
      this.getMarketingCompanies()
    }
  },
  methods: {
    debounceAgencyUrl: _.debounce(function (val) {
      this.form.agencyUrl = val
      this.$forceUpdate()
    }, 200),

    debounceAgencyName: _.debounce(function (val) {
      this.form.agencyName = val
      this.$forceUpdate()
    }, 200),

    filterResults () {
      if (
        this.form.sitesMin !== '' ||
        this.form.arcScoreMin !== '' ||
        this.form.arcScoreMax !== '' ||
        this.form.agencyName !== '' ||
        this.form.agencyUrl !== '' ||
        (typeof this.form.tierMax === 'number')
      ) {
        // TODO: Find better way to filter to avoid overchaining
        var list = this.formData.marketingCompanies
          .filter(this.filterByAgencyName)
          .filter(this.filterByAgencyUrl)
          .filter(this.filterBySiteMin)
          .filter(this.filterByArcScoreMin)
          .filter(this.filterByArcScoreMax)
          .filter(this.filterByTierMax)
        this.filteredResults.marketing_companies = list
      } else {
        this.filteredResults.marketing_companies = this.formData.marketingCompanies
      }
    },

    filterByAgencyName (result) {
      return this.form.agencyName !== ''
        ? (result.name.toLowerCase().includes(this.form.agencyName.toLowerCase()))
        : true
    },

    filterByAgencyUrl (result) {
      return this.form.name !== ''
        ? (result.url.toLowerCase().includes(this.form.agencyUrl.toLowerCase()))
        : true
    },

    filterBySiteMin (result) {
      return this.form.sitesMin !== ''
        ? (parseInt(this.form.sitesMin) <= result.domain_count)
        : true
    },

    filterByArcScoreMin (result) {
      return this.form.arcScoreMin !== ''
        ? (result.avg_arc_score >= parseInt(this.form.arcScoreMin))
        : true
    },

    filterByArcScoreMax (result) {
      return this.form.arcScoreMax !== ''
        ? (result.avg_arc_score <= parseInt(this.form.arcScoreMax))
        : true
    },

    filterByTierMax (result) {
      return (typeof this.form.tierMax === 'number')
        ? (result.avg_market_tier <= this.form.tierMax)
        : true
    },

    getMarketingCompanies () {
      this.loading = true
      this.$arc.get('marketing_companies?page=1&per_page=1000')
        .then(res => {
          this.filteredResults = res.data
          this.formData.marketingCompanies = res.data.marketing_companies
          this.getPagedResults()
        })
        .catch(err => {
          console.error(err)
          this.loading = false
        })
      // this.filteredResults = this.formData.marketingCompanies
    },

    getPagedResults () {
      const promises = []
      for (
        let i = 2;
        i < Math.ceil(this.filteredResults.total / this.filteredResults.per_page) + 1;
        i++
      ) {
        promises.push(
          this.$arc.get(`marketing_companies?page=${i}&per_page=1000`)
        )
      }
      Promise.all(promises).then((values) => {
        values.map(p => {
          this.filteredResults.marketing_companies.push(...p.data.marketing_companies)
        })
        this.formData.marketingCompanies = this.filteredResults.marketing_companies
      })
      this.loading = false
    },

    sortedClass (key) {
      return this.sort.key === key
        ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}`
        : ''
    },

    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false
      this.sort.key = key
    },

    changePage (e) {
      this.pagination.page = e
    },

    goToPage (evt) {
      this.getMarketingCompanies(evt)
    },

    clearFilters () {
      var self = this
      Object.keys(this.form).forEach(function (key) {
        self.form[key] = ''
        return self.form
      })
      this.$refs.filterDropdown1.selected = ''
      this.filteredResults.marketing_companies = this.formData.marketingCompanies
    }
  }
}
</script>

<style lang="scss">
.agencies {
  form {
    align-items: flex-end;
    margin-bottom: 30px;
    justify-content: space-between;
    strong {
      margin-bottom: 1rem;
    }
    > div {
      flex-basis: calc((100% / 4) - 30px) !important;
      &.flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        label {
          font-weight: 600;
          margin-bottom: 10px;
          display: block;
          width: 100%;
        }
        .input-container {
          flex-basis: calc((100% / 2) - 7px);
        }
      }
    }
    strong {
      margin-right: 15px;
    }
  }
  .logo-container {
    img {
      margin: 0 1rem;
      vertical-align: bottom;
      display: inline-block;
    }
  }
}
</style>
