<template>
  <BaseLayout
    title="Agency Comparison"
    :customClasses="['comparison']"
    :centerHeading="true"
    ref="baseLayout">
    <div id="comparison-app" v-if="showData">
      <Loading
        v-show="loading"
        text="Loading comparison data" />
      <div
        id="comparison-data"
        v-if="!loading && Object.entries(versus).length > 0">
        <div class="flex">
          <Table
            :loading="loading"
            v-for="(profile, i) in Object.entries(versus)"
            :key="i"
            :hasHeader="true">
            {{ profile }}
            <template
              slot="header"
              v-if="profile[1].profile !== undefined && profile[1].profile.marketing_company.icon_url !== null">
              <div class="logo-container">
                <span
                  class="delete-img"
                  @click="deleteCompanyIcon(profile[1].id)">
                  <font-awesome-icon
                    icon="times" />
                </span>
                <img :src="profile[1].profile !== undefined && profile[1].profile.marketing_company.icon_url" alt="">
              </div>
            </template>
            <template
              slot="header"
              v-else-if="profile[1].profile !== undefined && profile[1].profile.marketing_company.favicon_url !== null">
              <div class="logo-container">
                <img :src="profile[1].profile !== undefined && profile[1].profile.marketing_company.favicon_url" alt="">
                <h2>{{profile[1].profile.marketing_company.name}}</h2>
              </div>
            </template>
            <template
              slot="header"
              v-else>
              <action-links
                :noMargin="true">
                <Input
                  type="file"
                  ref="icon"
                  :hasLabel="true"
                  labelText="Upload icon"
                  :inputId="`uploadIcon-${profile[1].id}`"
                  @change="handleIconUpload(profile[1].id, $event)" />
              </action-links>
            </template>
            <template slot="body">
              <t-row>
                <t-cell>Agency {{ i + 1 }}</t-cell>
                <t-cell>
                  <div class="flex" id="agency-profile">
                    <span>
                      {{ profile[1].url }}
                    </span>
                    <Button
                      :hasLink="true"
                      :btnLink="{ path: 'agency', query: { id: profile[1].id } }"
                      :isView="true" />
                  </div>
                </t-cell>
              </t-row>
              <t-row>
                <t-cell>ARC Score Average</t-cell>
                <t-cell>
                  <strong
                    class="score large-text">
                    {{ agencyAverages[i] }}
                    <inline-svg
                      class="checkmark"
                      v-if="agencyAverages[i] === avgScoreWinner"
                      :src="require('../assets/img/check-mark-green.svg')"></inline-svg>
                  </strong>
                </t-cell>
              </t-row>
              <t-row>
                <t-cell>Number of websites</t-cell>
                <t-cell>
                  <strong style="font-size: 18px;">
                    {{ profile[1].domain_count.toLocaleString() }}
                  </strong>
                </t-cell>
              </t-row>
              <t-row>
                <t-cell
                  colspan="2">
                  <div>Number of websites by practice area</div>
                  <div class="cells">
                    <div class="cell"
                      v-for="(area, i) in profile[1].domains_by_practice_area"
                      :key="i">
                      <span><strong>{{ area.domains.length }}</strong></span>
                      <span>{{ area.practice_area }}</span>
                    </div>
                  </div>
                </t-cell>
              </t-row>
            </template>
          </Table>
          <action-links
            id="toggle-action">
            <Toggle
              :hasLabel="true"
              labelText="Include all locations, all domains"
              @change="toggleAgencyAvgAll($event.target.checked)"
              :isChecked="agencyAvgAll" />
          </action-links>
          <action-links
            id="comparison-action">
            <Button
              text="Run another comparison"
              @click="goToComparisonIndex" />
          </action-links>
        </div>
        <div id="head-to-head">
          <div class="flex">
            <h2>Head to Head</h2>
          </div>
          <Form>
            <strong>Filters</strong>
            <Dropdown
              :options="filteredHeadToHeadLocations"
              :isSearch="true"
              dropdownPlaceholder="Location"
              :inputHasLabel="false"
              inputId="location"
              @select="headToHeadFilters.location = $event"
              @input="headToHeadFilters.location = $event"
              :inputVal="headToHeadFilters.location" />
            <Dropdown
              :hasIcon="true"
              :options="headToHeadPracticeAreas"
              dropdownPlaceholder="Practice Area"
              :inputHasLabel="false"
              inputId="practiceArea"
              @select="headToHeadFilters.practiceArea = $event"
              @input="headToHeadFilters.practiceArea = $event"
              :inputVal="headToHeadFilters.practiceArea" />
            <Dropdown
              :options="headToHeadTiers"
              :hasIcon="true"
              dropdownPlaceholder="Tier"
              ref="tierDropdown"
              @select="headToHeadFilters.tier = $event" />
          </Form>
          <action-links>
            <Toggle
              :hasLabel="true"
              labelText="Show All"
              @change="toggleHeadToHead($event.target.checked)"
              :isChecked="headToHeadFilters.showAllResults" />
            <Button
              text="Clear filters"
              @click="clearHeadToHeadFilters" />
          </action-links>
          <p
            v-if="filteredHeadToHeadData.length === 0"
            style="margin-bottom:85px;">
            No head to head data found. Try clearing filters.
          </p>
          <div class="flex" v-if="filteredHeadToHeadData.length > 0 && versusData.length > 0">
            <div
              class="mkt-company-card"
              v-for="(company, idx) in versusData"
              :key="idx">
              <span class="title">
                <img :src="company.favicon_url" v-if="company.favicon_url">
                {{ company.name }}
              </span>
              <span class="wins">
                {{ idx === 0 ? headToHeadRecords.agency1.wins : headToHeadRecords.agency2.wins }} wins
              </span>
              <p class="record">
                Head to Head Record: {{
                  idx === 0
                    ? `${headToHeadRecords.agency1.wins} - ${headToHeadRecords.agency1.losses} - ${headToHeadRecords.ties}`
                    : `${headToHeadRecords.agency2.wins} - ${headToHeadRecords.agency2.losses} - ${headToHeadRecords.ties}`
                }}
              </p>
              <p v-show="headToHeadLoading">Loading win percentage...</p>
              <p v-show="!headToHeadLoading">
                Win Percentage =
                <span
                  :style="{
                    color: idx === 0
                      ? getWinPercentage(headToHeadRecords.agency1.wins, headToHeadRecords.agency1.losses) > getWinPercentage(headToHeadRecords.agency2.wins, headToHeadRecords.agency2.losses)
                        ? '#36b106'
                        : '#ef0f1a'
                      : getWinPercentage(headToHeadRecords.agency2.wins, headToHeadRecords.agency2.losses) > getWinPercentage(headToHeadRecords.agency1.wins, headToHeadRecords.agency1.losses)
                        ? '#36b106'
                        : '#ef0f1a'
                  }">
                  {{
                    idx === 0
                      ? getWinPercentage(headToHeadRecords.agency1.wins, headToHeadRecords.agency1.losses)
                      : getWinPercentage(headToHeadRecords.agency2.wins, headToHeadRecords.agency2.losses)
                  }}%
                </span>
              </p>
            </div>
          </div>
          <Table
            :loading="loading"
            v-if="filteredHeadToHeadData && filteredHeadToHeadData.length > 0">
            <template slot="head">
              <t-row>
                <t-heading>
                  Practice Area
                </t-heading>
                <t-heading>
                  Location
                </t-heading>
                <t-heading>
                  Agency 1 Score
                </t-heading>
                <t-heading>
                  Agency 2 Score
                </t-heading>
                <t-heading
                  :textCentered="true">
                  Winner
                </t-heading>
                <t-heading
                  :textCentered="true">
                  Market Tier
                </t-heading>
              </t-row>
            </template>
            <template slot="body">
              <t-row
                v-for="(pair, i) in filteredHeadToHeadData"
                :key="i">
                <t-cell>{{ capitalizeString(pair.practice_area_name) }}</t-cell>
                <t-cell>{{ formatLocation(pair.location) }}</t-cell>
                <t-cell>
                  <strong
                    class="agency1"
                    :class="{
                      'winner': pair.agency1 !== undefined &&
                        pair.agency2 !== undefined
                          ? pair.agency1.arc_score === pair.winning_score
                          : false
                    }">
                    {{ pair.agency1 !== undefined ? pair.agency1.arc_score : 'N/A' }}
                    <img
                      class="checkmark"
                      v-if="pair.agency1 !== undefined && pair.agency2 !== undefined ? pair.agency1.arc_score === pair.winning_score : false"
                      src="@/assets/img/check-mark-green.svg" />
                  </strong>
                  <span>{{ pair.agency1 !== undefined ? pair.agency1.website : 'N/A' }}</span>
                </t-cell>
                <t-cell>
                  <strong
                    class="agency2"
                    :class="{
                      'winner': pair.agency2 !== undefined &&
                        pair.agency1 !== undefined
                          ? pair.agency2.arc_score === pair.winning_score
                          : false
                    }">
                    {{ pair.agency2 !== undefined ? pair.agency2.arc_score : 'N/A' }}
                    <img
                      class="checkmark"
                      v-if="pair.agency2 !== undefined &&
                        pair.agency1 !== undefined
                          ? pair.agency2.arc_score === pair.winning_score
                          : false"
                      src="@/assets/img/check-mark-purple.svg" />
                  </strong>
                  <span>{{ pair.agency2 !== undefined ? pair.agency2.website : 'N/A' }}</span>
                </t-cell>
                <t-cell
                  :textCentered="true"
                  :class="[
                    pair.agency1 !== undefined && pair.agency2 !== undefined && pair.agency1.arc_score === pair.winning_score
                      ? ''
                      : pair.agency2 !== undefined && pair.agency1 !== undefined && pair.agency2.arc_score === pair.winning_score
                        ? ''
                        : 'grey'
                  ]">
                  <div class="logo-container" v-if="pair.agency1 !== undefined && pair.agency2 !== undefined && pair.agency1.arc_score === pair.winning_score">
                    <img height="24" width="24" v-if="pair.agency1.favicon_url"  :src="pair.agency1.favicon_url" />{{pair.agency1.marketing_company}}
                  </div>
                  <div class="logo-container" v-else-if="pair.agency2 !== undefined && pair.agency1 !== undefined && pair.agency2.arc_score === pair.winning_score">
                    <img height="24" width="24" v-if="pair.agency2.favicon_url"  :src="pair.agency2.favicon_url" />{{pair.agency2.marketing_company}}
                  </div>
                  <div v-else>
                    n/a
                  </div>
                </t-cell>
                <t-cell
                  :textCentered="true">
                  Tier {{ pair.market_tier }}
                </t-cell>
              </t-row>
            </template>
          </Table>
        </div>
        <div id="market-tier-comparison">
          <div class="flex">
            <h2>Comparison by Market Tier</h2>
          </div>
          <Chart
            width="100%"
            :options="chartOptions"
            v-if="!loading" />
          <Table
            :loading="loading"
            v-if="marketComparison.length > 0">
            <template slot="head">
              <t-row>
                <t-heading>Tier Level</t-heading>
                <t-heading>Agency 1</t-heading>
                <t-heading>Score</t-heading>
                <t-heading>Agency 2</t-heading>
                <t-heading>Score</t-heading>
                <t-heading
                  :textCentered="true">
                  Winner
                </t-heading>
              </t-row>
            </template>
            <template slot="body">
              <t-row
                v-for="(mkt, i) in marketComparison"
                :key="i">
                <t-cell>Tier {{ mkt.market_tier }}</t-cell>
                <t-cell>{{ mkt.agency1 }}</t-cell>
                <t-cell>
                  <strong class="agency1 green">
                    {{ mkt.agency1_market_avg }}
                    <img
                      class="checkmark"
                      v-if="mkt.agency1_market_avg === mkt.winning_score"
                      src="@/assets/img/check-mark-green.svg" />
                  </strong>
                </t-cell>
                <t-cell>{{ mkt.agency2 }}</t-cell>
                <t-cell>
                  <strong class="agency2 purple">
                    {{ mkt.agency2_market_avg }}
                    <img
                      class="checkmark"
                      v-if="mkt.agency2_market_avg === mkt.winning_score"
                      src="@/assets/img/check-mark-purple.svg" />
                  </strong>
                </t-cell>
                <t-cell>
                  <div class="logo-container" v-if="mkt.agency1 !== undefined && mkt.agency2 !== undefined && mkt.agency1_market_avg === mkt.winning_score">
                    <img height="24" width="24" v-if="mkt.agency1_favicon"  :src="mkt.agency1_favicon" />{{mkt.agency1}}
                  </div>
                  <div class="logo-container" v-else-if="mkt.agency2 !== undefined && mkt.agency1 !== undefined && mkt.agency2_market_avg === mkt.winning_score">
                    <img height="24" width="24" v-if="mkt.agency2_favicon"  :src="mkt.agency2_favicon" />{{mkt.agency2}}
                  </div>
                  <div v-else>
                    n/a
                  </div>
                </t-cell>
              </t-row>
            </template>
          </Table>
        </div>
        <div id="agency-client-scores">
          <div class="flex">
            <h2>Agency Client Scores</h2>
          </div>
          <tabs>
            <tab
              v-for="(company, index) in versusData"
              :key="company.name"
              :title="company.name">
              <h2>{{ company.name }}</h2>
              <Form
                @submit.prevent="getCompanyDomains(index, company.id)">
                <strong>Filters</strong>
                <Input
                  placeholder="Url"
                  v-model="formFilters[index].url"
                  :disabled="companyResults[index].loading" />
                <Dropdown
                  :options="filteredLocations(index)"
                  :isSearch="true"
                  dropdownPlaceholder="City"
                  :inputHasLabel="false"
                  inputId="city"
                  @select="formFilters[index].city = $event"
                  @input="formFilters[index].city = $event"
                  :inputVal="formFilters[index].city"
                  :isDisabled="companyResults[index].loading" />
                <Dropdown
                  :hasIcon="true"
                  :options="allPracticeAreas"
                  dropdownPlaceholder="Practice Area"
                  :inputHasLabel="false"
                  inputId="practiceArea"
                  :isDisabled="companyResults[index].loading"
                  @select="formFilters[index].practiceArea = $event"
                  @input="formFilters[index].practiceArea = $event"
                  :inputVal="formFilters[index].practiceArea" />
                <Dropdown
                  :options="formFilters[index].tierOptions"
                  :hasIcon="true"
                  dropdownPlaceholder="Tier"
                  :isDisabled="companyResults[index].loading"
                  ref="tierDropdown"
                  @select="formFilters[index].tier = $event" />
                <Dropdown
                  dropdownPlaceholder="Results per page"
                  :inputHasLabel="false"
                  :hasIcon="true"
                  :options="pagination[index].dropdownOptions"
                  :inputVal="paginatedData[index].per_page"
                  :isDisabled="companyResults[index].loading"
                  @select="changeTablePerPage(index, $event)" />
                <Button
                  type="submit"
                  text="Apply"
                  :isDisabled="companyResults[index].loading" />
              </Form>
              <action-links
                v-if="!companyResults[index].loading">
                <Toggle
                  :hasLabel="true"
                  labelText="Show All Results"
                  @change="toggleResults(index, company.id, $event.target.checked)"
                  :isChecked="formFilters[index].showAllResults" />
                <Button
                  @click="clearFilters(index, company.id)"
                  text="Clear filters" />
              </action-links>
              <Loading
                v-show="companyResults[index].loading"
                text="Loading results" />
              <Table
                v-if="!companyResults[index].loading && paginatedData[index].data.length > 0">
                <template slot="head">
                  <t-row>
                    <t-heading>#</t-heading>
                    <t-heading
                      @click="sortBy(agencyScoresSort, 'website')"
                      :class="sortedClass(agencyScoresSort, 'website')">
                      Url
                    </t-heading>
                    <t-heading
                      @click="sortBy(agencyScoresSort, 'arc_score')"
                      :class="sortedClass(agencyScoresSort, 'arc_score')">
                      ARC Score
                    </t-heading>
                    <t-heading
                      @click="sortBy(agencyScoresSort, 'practice_area_name')"
                      :class="sortedClass(agencyScoresSort, 'practice_area_name')">
                      Practice Area
                    </t-heading>
                    <t-heading
                      @click="sortBy(agencyScoresSort, 'location_name')"
                      :class="sortedClass(agencyScoresSort, 'location_name')">
                      City
                    </t-heading>
                    <t-heading
                      @click="sortBy(agencyScoresSort, 'market_tier')"
                      :class="sortedClass(agencyScoresSort, 'market_tier')">
                      Market Tier
                    </t-heading>
                    <t-heading
                      @click="sortBy(agencyScoresSort, 'last_checked_at')"
                      :class="sortedClass(agencyScoresSort, 'last_checked_at')">
                      Date Last Checked
                    </t-heading>
                    <t-heading></t-heading>
                  </t-row>
                </template>
                <template slot="body">
                  <t-row
                    v-for="(result, idx) in paginatedData[index].data"
                    :key="`${company.name}-${idx}`">
                    <t-cell>{{ result.arc_rank }}</t-cell>
                    <t-cell>
                      <a :href="result.website">
                        {{ result.website }}
                      </a>
                    </t-cell>
                    <t-cell>{{ result.arc_score }}</t-cell>
                    <t-cell>{{ capitalizeString(result.practice_area_name) }}</t-cell>
                    <t-cell>{{ formatLocation(result.location_name) }}</t-cell>
                    <t-cell>{{ result.market_tier }}</t-cell>
                    <t-cell>{{ result.last_checked_at | moment('M/DD/YYYY') }}</t-cell>
                    <t-cell>
                      <Button
                        :hasLink="true"
                        :btnLink="{ path: `${result.domain_slug}` }"
                        :isView="true" />
                    </t-cell>
                  </t-row>
                </template>
              </Table>
              <div
                class="table-pagination"
                v-if="!companyResults[index].loading && paginatedData[index].data.length > 0">
                <Pagination
                  :totalItems="paginatedData[index].total"
                  :perPage="paginatedData[index].per_page"
                  :currentPage="paginatedData[index].page"
                  :nextNum="paginatedData[index].next_page"
                  :prevNum="paginatedData[index].prev_page"
                  :isComputed="true"
                  @pageChanged="changeTablePage(index, $event)" />
              </div>
              <p v-if="!companyResults[index].loading && paginatedData[index].data.length === 0">
                No results found, please try again.
              </p>
            </tab>
          </tabs>
        </div>
      </div>
    </div>
    <div id="comparison-form" v-else>
      <Loading
        v-show="loading"
        text="Loading marketing companies" />
      <div class="container" v-if="!loading">
        <Form
          @submit.prevent="compareCompanies(
            comparisonForm[0].marketingCompanyDomain,
            comparisonForm[1].marketingCompanyDomain
          )">
          <div class="flex">
            <Dropdown
              v-for="(dropdown, idx) in comparisonForm"
              :key="idx"
              :isSearch="true"
              :inputHasLabel="true"
              :inputLabelText="`Select Agency ${idx + 1}`"
              :options="filteredCompanies[idx]"
              customOption="name"
              emptyResultsMessage="Start typing an agency name"
              dropdownPlaceholder="Marketing Company"
              @input="debounceAgencyName($event, idx)"
              :inputVal="comparisonForm[idx].marketingCompany"
              :ref="`filterDropdown${idx}`"
              @select="handleMarketingSelect(idx, $event)"
              :isDisabled="loading" />
          </div>
          <Button
            text="compare agencies"
            btnType="primary"
            type="submit" />
        </Form>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/views/BaseLayout'
import Table from '@/components/table/Table'
import TableDataCell from '@/components/table/TableDataCell'
import TableHeading from '@/components/table/TableHeading'
import TableRow from '@/components/table/TableRow'
import Loading from '@/components/misc/Loading'
import Toggle from '@/components/misc/Toggle'
import Tabs from '@/components/global/tabs/Tabs'
import Tab from '@/components/global/tabs/Tab'
import ActionLinks from '@/components/misc/ActionLinks'
import Input from '@/components/form/Input'
import Button from '@/components/form/Button'
import Pagination from '@/components/misc/Pagination'
import Dropdown from '@/components/form/Dropdown'
import Form from '@/components/form/Form'
import Chart from '@/components/charts/Chart'
import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'
// import { sha256 } from 'js-sha256'

export default {
  name: 'AgencyComparison',
  components: {
    BaseLayout,
    Table,
    't-cell': TableDataCell,
    't-heading': TableHeading,
    't-row': TableRow,
    Loading,
    ActionLinks,
    Pagination,
    Dropdown,
    Input,
    Chart,
    Form,
    Button,
    Toggle,
    Tabs,
    Tab
  },
  data () {
    return {
      loading: false,
      versus: {
        company1: {},
        company2: {}
      },
      pagination: [
        {
          currentPage: 1,
          perPage: 100,
          dropdownOptions: [20, 50, 100]
        },
        {
          currentPage: 1,
          perPage: 100,
          dropdownOptions: [20, 50, 100]
        }
      ],
      headToHeadFilters: {
        location: '',
        practiceArea: '',
        tier: '',
        showAllResults: false
      },
      formFilters: [
        {
          url: '',
          city: '',
          tier: '',
          practiceArea: '',
          tierOptions: [1, 2, 3, 4, 5],
          showAllResults: true
        },
        {
          url: '',
          city: '',
          tier: '',
          practiceArea: '',
          tierOptions: [1, 2, 3, 4, 5],
          showAllResults: true
        }
      ],
      agencyScoresSort: {
        sort: {
          key: 'arc_score',
          isAsc: true
        }
      },
      companyResults: [
        {
          loading: false,
          domains: []
        },
        {
          loading: false,
          domains: []
        }
      ],
      companies: [],
      svgIcon: require('../assets/img/check-mark-green.svg'),
      comparisonForm: [
        {
          marketingCompany: '',
          marketingCompanyDomain: {}
        },
        {
          marketingCompany: '',
          marketingCompanyDomain: {}
        }
      ],
      allHeadToHeadDomains: [],
      agencyAvgAll: false,
      chartOptions: {
        chart: {
          type: 'scatter',
          zoomType: 'xy',
          spacingBottom: 35,
          backgroundColor: '#fbfdff'
        },
        credits: {
          enabled: false
        },
        title: {
          text: 'Arc Scores in each Market Tier by Company'
        },
        subtitle: {
          text: 'Source: https://arc.1point21interactive.com'
        },
        xAxis: {
          title: {
            enabled: true,
            text: 'ARC Score'
          },
          startOnTick: true,
          endOnTick: true,
          showLastLabel: true
        },
        yAxis: {
          title: {
            text: 'Tier Level'
          },
          min: 0,
          max: 5
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          y: 30,
          floating: true
        },
        plotOptions: {
          series: {
            opacity: 0.75,
            marker: {
              symbol: 'circle'
            }
          },
          scatter: {
            jitter: {
              y: 0.5,
              x: -0.15
            },
            marker: {
              radius: 4,
              states: {
                hover: {
                  enabled: true,
                  lineWidth: 0
                }
              }
            },
            states: {
              hover: {
                marker: {
                  enabled: false
                }
              }
            },
            tooltip: {
              headerFormat: `
                <b style="text-transform:capitalize;">
                  {series.name}
                </b><br>
              `,
              pointFormat: 'Score: {point.x}, Tier: {point.y}'
            }
          }
        },
        series: []
      },
      headToHeadCache: {},
      pendingRequests: {},
      headToHeadData: [],
      headToHeadLoading: false,
      filteredCompanies: []
    }
  },
  computed: {
    ...mapGetters({
      allPracticeAreas: 'form/allPracticeAreas',
      allUsLocations: 'form/allUsLocations'
    }),

    ...mapState({
      formData: state => state.form
    }),

    filteredLocations () {
      return index => this.searchLocationList(this.formFilters[index].city, this.allUsLocations).slice(0, 50)
    },

    filteredHeadToHeadLocations () {
      return this.searchLocationList(
        this.headToHeadFilters.location,
        this.headToHeadLocations
      ).slice(0, 50).sort((a, b) => {
        if (a < b) { return -1 }
        if (a > b) { return 1 }
        return 0
      })
    },

    filteredHeadToHeadData () {
      return (
        this.headToHeadFilters.location !== '' ||
        this.headToHeadFilters.practiceArea !== '' ||
        (this.headToHeadFilters.tier !== '' && this.headToHeadFilters.tier !== undefined)
      ) ? this.headToHeadData
          .filter(this.filterByLocation)
          .filter(this.filterByPracticeArea)
          .filter(this.filterByMarketTier)
        : this.headToHeadData
    },

    allDomains () {
      return (
        this.companyResults[0].domains.length > 0 &&
        this.companyResults[1].domains.length > 0
      )
        ? [
          ...this.companyResults[0].domains,
          ...this.companyResults[1].domains
        ]
        : []
    },

    versusData () {
      const versusArr = []
      if (Object.keys(this.versus.company1).length !== 0 && Object.keys(this.versus.company2).length !== 0) {
        Object.entries(this.versus).forEach(company => {
          versusArr.push(company[1])
        })
      }
      return versusArr
    },

    avgScoreWinner () {
      return !this.loading
        ? this.lowest(
          this.agency1AvgScore,
          this.agency2AvgScore
        )
        : ''
    },

    agencyAverages () {
      return [this.agency1AvgScore, this.agency2AvgScore]
    },

    agency1AvgScore () {
      const arcScoreList = []
      const domainList = this.agencyAvgAll ? this.versusData[0].profile.domains : this.versusData[0].profile.domains_not_agg
      domainList.forEach(domain => {
        arcScoreList.push(domain.arc_score)
      })
      return arcScoreList.length > 0
        ? parseFloat((arcScoreList.reduce((a, b) => a + b) / arcScoreList.length).toFixed(2))
        : null
    },

    agency2AvgScore () {
      const arcScoreList = []
      let domainList = []
      domainList = this.agencyAvgAll ? this.versusData[1].profile.domains : this.versusData[1].profile.domains_not_agg
      domainList.forEach(domain => {
        if (domain.arc_score) {
          arcScoreList.push(domain.arc_score)
        }
      })

      return arcScoreList.length > 0
        ? parseFloat((arcScoreList.reduce((a, b) => a + b) / arcScoreList.length).toFixed(2))
        : null
    },

    // headToHeadData () {
    //   return this.headToHeadFilters.showAllResults
    //     ? this.groupDomains(false, true)
    //     : this.groupDomains(true, true)
    // },
    // headToHeadData () {
    //   return this.headToHeadFilters.showAllResults
    //     ? this.groupDomains(false, true)
    //     : this.groupDomains(true, true)
    // },

    paginatedData () {
      return this.sortedResults.length > 0
        ? [
          this.paginateData(
            this.sortedResults[0].length > 0 ? this.sortedResults[0] : [],
            this.pagination[0].currentPage,
            this.pagination[0].perPage
          ),
          this.paginateData(
            this.sortedResults[1].length > 0 ? this.sortedResults[1] : [],
            this.pagination[1].currentPage,
            this.pagination[1].perPage
          )
        ]
        : []
    },

    sortedResults () {
      return [this.company1SortedResults, this.company2SortedResults]
    },

    company1SortedResults () {
      const list = this.companyResults[0].domains.length > 0 ? this.companyResults[0].domains : []
      if (this.agencyScoresSort.sort.key) {
        list.sort((a, b) => {
          a = a[this.agencyScoresSort.sort.key]
          b = b[this.agencyScoresSort.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.agencyScoresSort.sort.isAsc ? 1 : -1)
        })
      }
      return list.filter(result => !this.isBlacklisted(result.website))
    },

    company2SortedResults () {
      const list = this.companyResults[1].domains.length > 0 ? this.companyResults[1].domains : []
      if (this.agencyScoresSort.sort.key) {
        list.sort((a, b) => {
          a = a[this.agencyScoresSort.sort.key]
          b = b[this.agencyScoresSort.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.agencyScoresSort.sort.isAsc ? 1 : -1)
        })
      }
      return list.filter(result => !this.isBlacklisted(result.website))
    },

    marketComparison () {
      return this.getComparisonData(this.versusData[0], this.versusData[1]).sort((a, b) => {
        return (a.market_tier - b.market_tier)
      })
    },

    showData () {
      return Object.keys(this.$route.query).length === 2
    },

    headToHeadLocations () {
      const locations = []
      const grouped = _.groupBy(this.headToHeadData, 'location')
      Object.keys(grouped).forEach(key => locations.push(key.replace(/,/g, ', ')))
      return locations
    },

    headToHeadPracticeAreas () {
      const practiceAreas = []
      const grouped = _.groupBy(this.headToHeadData, 'practice_area_name')
      Object.keys(grouped).forEach(key => practiceAreas.push(key))
      return practiceAreas
    },

    headToHeadTiers () {
      const tiers = []
      const grouped = _.groupBy(this.headToHeadData, 'market_tier')
      Object.keys(grouped).forEach(key => tiers.push(key))
      return tiers
    },

    headToHeadRecords () {
      return this.getHeadToHeadRecords()
    }
  },
  created () {
    this.getMarketingCompanies()
  },
  watch: {
    showData: {
      handler: function (val) {
        this.getMarketingCompanies()
      }
    },
    company2SortedResults: {
      handler: function (val) {
        if (val && val.length > 0) {
          this.chartOptions.series = this.getChartData()
        }
      }
    },
    'headToHeadFilters.showAllResults': {
      handler: 'fetchHeadToHeadData',
      immediate: true
    }
  },
  methods: {
    // 1. Get each marketing company
    // 1a. Get all marketing companies, for agency comparison form page (dropdowns)
    getMarketingCompanies () {
      this.loading = true
      this.companies = this.formData.marketingCompanies
      if (this.showData) {
        this.setMarketingCompanies() // (1c)
      } else {
        this.loading = false
      }
    },

    // 1b. Push new queries to same route
    compareCompanies (company1, company2) {
      this.$router.push({
        path: 'agency-comparison',
        query: {
          company1: `${this.formatDomain(company1.name)}`,
          company2: `${this.formatDomain(company2.name)}`
        }
      })
    },

    // 1c. Get both query values, search marketing company list, then set this.versus values
    async setMarketingCompanies () {
      this.versus.company1 = this.companies.filter(item => item.url.toLowerCase().includes(this.$route.query.company1))[0]
      this.versus.company2 = this.companies.filter(item => item.url.toLowerCase().includes(this.$route.query.company2))[0]
      const getMarketingCompanyByName = async (name, perPage = 500) => {
        const resp = await this.$arc.get('marketing_companies', {
          params: {
            ...(perPage ? { per_page: perPage } : {}),
            ...(name ? { name_like: name } : {})
          }
        })
        return resp.data.marketing_companies[0]
      }
      // this.versus.company1 = this.comparisonForm[0].marketingCompany !== '' ? this.comparisonForm[0].marketingCompanyDomain : undefined
      // this.versus.company2 = this.comparisonForm[1].marketingCompany !== '' ? this.comparisonForm[1].marketingCompanyDomain : undefined
      if (this.versus.company1 !== undefined && this.versus.company2 !== undefined) {
        this.getProfiles()
      } else {
        if (this.versus.company1 === undefined && this.versus.company2 === undefined) {
          const res1 = await getMarketingCompanyByName(this.$route.query.company1)
          const res2 = await getMarketingCompanyByName(this.$route.query.company2)
          this.versus.company1 = res1
          this.versus.company2 = res2
          if (this.versus.company1 !== undefined && this.versus.company2 !== undefined) {
            this.getProfiles()
          } else {
            if (this.versus.company1 === undefined) {
              alert(`${this.$route.query.company1} not found, please check spelling and try again.`)
            } else {
              alert(`'${this.$route.query.company2}' not found, please check spelling and try again.`)
            }
          }
        }
      }
    },

    // 2. Set top section data
    async getProfiles () {
      Object.entries(this.versus).forEach(async (company, key) => {
        const mktComp = company[1]
        mktComp.profile = await this.getCompanyProfile(company[1].id)
        const arrOfPaObjects = this.groupArrayOfObjects(mktComp.profile.domains, 'practice_area_name')

        mktComp.domains_by_practice_area = Object.entries(arrOfPaObjects).map(item => {
          const uniqueDomains = _.uniqBy(item[1], function (obj) {
            return obj.website
          })

          const obj = {}
          obj.practice_area = item[0]
          obj.domains = uniqueDomains
          return obj
        })

        if (mktComp.name !== undefined || mktComp.name !== '') {
          mktComp.profile.domains.forEach(domain => {
            domain.marketing_company = mktComp.name
            domain.favicon_url = mktComp.favicon_url
          })
        }

        this.companyResults[key].domains = mktComp.profile.domains
        this.headToHeadData = await this.groupDomains()
        this.getArcRank(this.company1SortedResults)
        this.getArcRank(this.company2SortedResults)
        this.$forceUpdate()
        const profilesLoaded = !_.isEmpty(this.versus.company1.profile) && !_.isEmpty(this.versus.company2.profile)
        if (profilesLoaded) {
          this.allHeadToHeadDomains = this.allDomains
          this.loading = false
        }
      })
    },

    async getCompanyProfile (companyId) {
      const allDomainsWithAgg = await this.getAllDomains(companyId, true)
      const allDomainsWithoutAgg = await this.getAllDomains(companyId, false)

      return {
        notes: allDomainsWithAgg.notes,
        marketing_company: allDomainsWithAgg.marketing_company,
        files: allDomainsWithAgg.files,
        domains: allDomainsWithAgg.domains,
        domains_not_agg: allDomainsWithoutAgg.domains
      }
    },

    // Loop through paginated results, then get them concurrently with promise.all
    async getAllDomains (companyId, agg) {
      const { data } = await this.getDomains(companyId, agg, 1, 1000)
      const promises = [Promise.resolve({ data })]

      if (data.next_num !== null) {
        let currentPage = 2
        const totalPages = Math.ceil(data.total / data.per_page)

        for (currentPage; currentPage <= totalPages; currentPage++) {
          promises.push(this.getDomains(companyId, agg, currentPage, 1000))
        }
      }

      const asyncResults = await Promise.all(promises)
      const allDomains = asyncResults.map(obj => obj.data.domains).flat()

      return {
        files: data.files,
        marketing_company: data.marketing_company,
        notes: data.notes,
        domains: allDomains
      }
    },

    // Get all domains with agg: location param
    async getDomains (id, agg, page, perPage) {
      return await this.$arc.get(`marketing_companies/${id}`, {
        params: {
          ...(page ? { page: page } : {}),
          ...(perPage ? { per_page: perPage } : {}),
          ...(agg ? { agg: 'location' } : {})
        }
      })
    },

    getWinPercentage (wins, losses) {
      // round to whole number
      return Math.round((wins / (wins + losses)) * 100)
    },

    async fetchHeadToHeadData () {
      try {
        this.headToHeadData = await this.groupDomains(
          !this.headToHeadFilters.showAllResults,
          true
        )
      } catch (error) {
        console.error('Error fetching head to head data', error)
        this.headToHeadData = []
      } finally {
      }
    },

    async groupDomains (latestScore = true, headToHead = false) {
      this.headToHeadLoading = true // Set loading to true at the start

      try {
        if (this.allDomains && this.allDomains.length > 0) {
          const practiceAreas = headToHead
            ? _.groupBy(this.allHeadToHeadDomains, 'practice_area_name')
            : _.groupBy(this.allDomains, 'practice_area_name')

          const paArr = []
          Object.entries(practiceAreas).forEach(paName => {
            paArr.push(paName[1])
          })

          const res = []
          paArr.forEach(pa => {
            const pair = _.chain(pa)
              .groupBy('location_name')
              .map((results, location) => {
                return {
                  location: location,
                  companies: _.groupBy(results, 'marketing_company')
                }
              })
              .value()
            res.push(pair)
          })

          const filteredArrs = []
          res.forEach(location => {
            const filtered = location.filter(loc => {
              return Object.entries(loc.companies).length === 2
            })
            filteredArrs.push(filtered)
          })

          const combos = []
          const agencies = []
          const arcScorePromises = [] // Collect promises for async calls

          filteredArrs.forEach(arr => {
            arr.forEach(location => {
              location.results = []
              Object.entries(location.companies).forEach(mkt => {
                agencies.push(mkt[0])

                mkt[1].sort((a, b) => {
                  return a.arc_score - b.arc_score
                })

                if (latestScore && mkt[1].length > 0) {
                  location.results.push(mkt[1][0])
                } else {
                  location.results.push(mkt[1])
                }
              })
              combos.push(location)
            })
          })

          const latestCombos = []
          const allCombos = []

          combos.forEach(obj => {
            if (obj.results[0]?.length > 1 || obj.results[1]?.length > 1) {
              const combined = obj.results[0]?.map((item, index) => {
                return [item, obj.results[1]?.[index]].sort((a, b) => (a.arc_score || 0) - (b.arc_score || 0))
              }) || []

              combined.forEach(res => {
                const agency1 = res.find(obj => obj && obj.marketing_company === this.versusData[0].name)
                const agency2 = res.find(obj => obj && obj.marketing_company === this.versusData[1].name)

                const allObj = {
                  location: agency1 ? agency1.location_name : agency2?.location_name,
                  practice_area_name: agency1 ? agency1.practice_area_name : agency2?.practice_area_name,
                  market_tier: agency1 ? agency1.market_tier : agency2?.market_tier,
                  agency1: agency1,
                  agency2: agency2
                }

                const arcScorePromise = Promise.all([
                  this.checkAndPopulateArcScore(allObj.agency1),
                  this.checkAndPopulateArcScore(allObj.agency2)
                ]).then(() => {
                  allObj.winning_score = this.lowest(
                    allObj.agency1?.arc_score || 99,
                    allObj.agency2?.arc_score || 99
                  )
                  allCombos.push(allObj)
                })

                arcScorePromises.push(arcScorePromise)
              })
            } else {
              const latestObj = {
                location: obj.location,
                practice_area_name: obj.results[0]?.practice_area_name,
                market_tier: obj.results[0]?.market_tier,
                agency1: obj.results[0],
                agency2: obj.results[1]
              }

              const arcScorePromise = Promise.all([
                this.checkAndPopulateArcScore(latestObj.agency1),
                this.checkAndPopulateArcScore(latestObj.agency2)
              ]).then(() => {
                latestObj.winning_score = this.lowest(
                  latestObj.agency1?.arc_score || 99,
                  latestObj.agency2?.arc_score || 99
                )
                latestCombos.push(latestObj)
              })

              arcScorePromises.push(arcScorePromise)
            }
          })

          // Wait for all ARC score promises to resolve
          await Promise.all(arcScorePromises)

          return latestScore ? latestCombos : _.flatten(allCombos)
        }
      } catch (error) {
        console.error('Error grouping domains', error)
      } finally {
        this.headToHeadLoading = false // Ensure loading is set to false at the very end
        this.$forceUpdate()
      }
    },

    // Helper function to check cache, deduplicate requests, and populate arc score
    async checkAndPopulateArcScore (agency) {
      if (!agency) return

      const strippedDomain = agency.website.replace(/(^\w+:|^)\/\//, '').replace(/^www\./, '').replace(/\/$/, '')

      // Use cache if available
      if (this.headToHeadCache[strippedDomain]) {
        // console.log(`Using cached score history for ${strippedDomain}`)
        this.assignArcScoreFromCache(agency, strippedDomain)
        return
      }

      // If a request for this domain is already in progress, wait for it to complete
      if (this.pendingRequests[strippedDomain]) {
        await this.pendingRequests[strippedDomain]
        this.assignArcScoreFromCache(agency, strippedDomain)
        return
      }

      // Otherwise, start a new request and add it to the pendingRequests cache
      this.pendingRequests[strippedDomain] = (async () => {
        // console.log('Fetching ARC score for', strippedDomain)
        try {
          const { data } = await this.$arc.get(`domains/${strippedDomain}/arc_scores?skip_results_excl_from_arc_score_calc=1`)

          // Cache the result for future requests
          this.headToHeadCache[strippedDomain] = data.arc_score_history
          this.headToHeadCache[strippedDomain].results_excl_from_arc_score_calc = data.results_excl_from_arc_score_calc

          // Assign arc score after caching the data
          this.assignArcScoreFromCache(agency, strippedDomain)
        } catch (error) {
          console.error(`Error fetching ARC score for ${strippedDomain}`, error)
        } finally {
          // Remove the pending request from cache
          delete this.pendingRequests[strippedDomain]
        }
      })()

      // Await the in-progress request to avoid redundant API calls
      await this.pendingRequests[strippedDomain]
    },

    // Function to assign arc score from the cache
    assignArcScoreFromCache (agency, strippedDomain) {
      const filteredSites = this.headToHeadCache[strippedDomain].filter(item => {
        return (
          item.location === agency.location_name &&
          item.practice_area_name === agency.practice_area_name &&
          item.market_tier === agency.market_tier &&
          !this.headToHeadCache[strippedDomain].results_excl_from_arc_score_calc.includes(item.result_id) &&
          item.marketing_company === agency.marketing_company
        )
      })
      const sortedSites = filteredSites.sort((a, b) => new Date(b.last_checked_at) - new Date(a.last_checked_at))
      if (sortedSites.length > 0) {
        agency.arc_score = sortedSites[0].arc_score
      }
    },

    async populateArcScore (agency) {
      if (agency) {
        const strippedDomain = agency.website.replace(/(^\w+:|^)\/\//, '').replace(/^www\./, '').replace(/\/$/, '')

        // Check if arc score history is already cached
        if (this.headToHeadCache[strippedDomain]) {
          // console.log(`Using cached score history for ${strippedDomain}`)
          const filteredSites = this.headToHeadCache[strippedDomain].filter(item => {
            return (
              item.location === agency.location_name &&
              item.practice_area_name === agency.practice_area_name &&
              item.market_tier === agency.market_tier &&
              !this.headToHeadCache[strippedDomain].results_excl_from_arc_score_calc.includes(item.result_id) &&
              item.marketing_company === agency.marketing_company
            )
          })
          const sortedSites = filteredSites.sort((a, b) => new Date(b.last_checked_at) - new Date(a.last_checked_at))
          if (sortedSites.length > 0) {
            agency.arc_score = sortedSites[0].arc_score
          }
        } else {
          // console.log('Fetching ARC score for', strippedDomain)
          try {
            const { data } = await this.$arc.get(`domains/${strippedDomain}/arc_scores?skip_results_excl_from_arc_score_calc=1`)

            // Cache the arc score history and exclusion list for this domain
            this.headToHeadCache[strippedDomain] = data.arc_score_history
            this.headToHeadCache[strippedDomain].results_excl_from_arc_score_calc = data.results_excl_from_arc_score_calc

            const filteredSites = data.arc_score_history.filter(item => {
              return (
                item.location === agency.location_name &&
                item.practice_area_name === agency.practice_area_name &&
                item.market_tier === agency.market_tier &&
                !data.results_excl_from_arc_score_calc.includes(item.result_id) &&
                item.marketing_company === agency.marketing_company
              )
            })
            const sortedSites = filteredSites.sort((a, b) => new Date(b.last_checked_at) - new Date(a.last_checked_at))
            if (sortedSites.length > 0) {
              agency.arc_score = sortedSites[0].arc_score
            }
          } catch (error) {
            console.error(`Error fetching ARC score for ${strippedDomain}`, error)
          }
        }
      }
    },

    cleanDomain (domain) {
      return domain
        .replace(/^https?:\/\//, '')
        .replace(/^www\./, '')
        .split('/')[0] // Ensures we only keep the main domain without path
    },

    // 4. Set market comparison data
    // 4a. (Helper) Get avg score of each market tier
    getMarketAverages (arr) {
      const marketKeys = [1, 2, 3, 4, 5]
      const marketArrays = []

      const getAverage = (arr) => {
        return arr.reduce((acc, val) => {
          return acc + (val / arr.length)
        }, 0).toFixed(2)
      }

      marketKeys.forEach(key => {
        const arcScoreArr = []
        arr.forEach(item => {
          if (item.market_tier === key) {
            arcScoreArr.push(item.arc_score)
          }
        })
        marketArrays.push({
          market_tier: key,
          avg_mkt_score: getAverage(arcScoreArr)
        })
      })

      return marketArrays
    },
    // 4b. Create market comparison data
    getComparisonData (company1, company2) {
      const marketKeys = [1, 2, 3, 4, 5]
      const marketData = []
      if (Object.keys(company1.profile).length !== 0 && Object.keys(company2.profile).length !== 0) {
        marketKeys.forEach(key => {
          const marketTierObj = {
            market_tier: key,
            agency1: company1.name,
            agency1_favicon: company1.favicon_url,
            agency2: company2.name,
            agency2_favicon: company2.favicon_url,
            agency1_market_avg: parseFloat(this.getMarketAverages(this.agencyAvgAll ? company1.profile.domains : company1.profile.domains_not_agg).filter(mkt => {
              return mkt.market_tier === key
            })[0].avg_mkt_score),
            agency2_market_avg: parseFloat(this.getMarketAverages(this.agencyAvgAll ? company2.profile.domains : company2.profile.domains_not_agg).filter(mkt => {
              return mkt.market_tier === key
            })[0].avg_mkt_score)
          }
          const lowAvg = this.lowest(
            marketTierObj.agency1_market_avg,
            marketTierObj.agency2_market_avg
          )
          marketTierObj.winning_score = lowAvg
          marketData.push(marketTierObj)
        })
      }
      return marketData
    },

    // 5. Set company results data
    getCompanyDomains (idx, id) {
      this.companyResults[idx].loading = true
      const location = this.formFilters[idx].city.split(',')
      this.$arc.get(`marketing_companies/${id}`, {
        params: {
          ...(this.formFilters[idx].url ? { domain_name_like: `${this.formFilters[idx].url.trim()}` } : {}),
          ...(this.formFilters[idx].city ? { location_name_like: `${location[0]}` } : {}),
          ...(this.formFilters[idx].tier ? { market_tier: this.formFilters[idx].tier } : {}),
          ...(this.formFilters[idx].practiceArea ? { practice_area_name: this.formFilters[idx].practiceArea } : {}),
          ...(this.formFilters[idx].showAllResults ? { agg: 'location' } : {})
        }
      })
        .then(res => {
          this.companyResults[idx].domains = res.data.domains
          this.getArcRank(this.company1SortedResults)
          this.getArcRank(this.company2SortedResults)
          this.$forceUpdate()
          this.companyResults[idx].loading = false
        })
        .catch(err => {
          console.error(err)
          this.companyResults[idx].loading = false
        })
    },

    getChartData () {
      const allCompanyDomains = {
        company1: this.versus.company1?.profile?.domains || [],
        company2: this.versus.company2?.profile?.domains || []
      }
      const data = Object.entries(allCompanyDomains).map(item => {
        const obj = {}
        switch (item[0]) {
          case 'company1':
            obj.color = '#0F71F7'
            break
          case 'company2':
            obj.color = '#F8572C'
            break
        }
        if (item[1]?.length > 0) {
          obj.name = item[1][0]?.marketing_company || 'Unknown Company'
          obj.data = item[1].map(res => {
            return [res.arc_score, res.market_tier]
          })
        } else {
          obj.name = 'No Data'
          obj.data = []
        }
        return obj
      })
      return data
    },

    getHeadToHeadRecords () {
      const agency1Wins = this.filteredHeadToHeadData.filter(item => {
        return (
          (item.winning_score === item.agency1?.arc_score) &&
          !(item.agency2 === undefined && item.agency1?.arc_score === item.winning_score)
        )
      })
      const agency2Wins = this.filteredHeadToHeadData.filter(item => {
        return (
          (item.winning_score === item.agency2?.arc_score) &&
          !(item.agency1 === undefined && item.agency2?.arc_score === item.winning_score)
        )
      })
      const ties = this.filteredHeadToHeadData.filter(item => {
        return (
          (item.winning_score === item.agency1?.arc_score && item.winning_score === item.agency2?.arc_score) ||
          (item.agency1 === undefined && item.agency2?.arc_score === item.winning_score) ||
          (item.agency2 === undefined && item.agency1?.arc_score === item.winning_score)
        )
      })
      return {
        agency1: {
          wins: agency1Wins.length,
          losses: agency2Wins.length
        },
        agency2: {
          wins: agency2Wins.length,
          losses: agency1Wins.length
        },
        ties: ties.length
      }
    },

    // Filters
    // Head to head filters
    filterByLocation (data) {
      return this.headToHeadFilters.location !== ''
        ? (data.location.toLowerCase().includes(this.headToHeadFilters.location.replace(/, /g, ',').toLowerCase()))
        : true
    },

    filterByPracticeArea (data) {
      return this.headToHeadFilters.practiceArea !== ''
        ? (data.practice_area_name === this.headToHeadFilters.practiceArea)
        : true
    },

    filterByMarketTier (data) {
      return this.headToHeadFilters.tier !== undefined
        ? (parseInt(data.market_tier) === parseInt(this.headToHeadFilters.tier))
        : true
    },
    // End of head to head filters

    handleMarketingSelect (idx, e) {
      this.comparisonForm[idx].marketingCompany = e
      if (this.comparisonForm[idx].marketingCompany !== '') {
        const companyObj = this.filteredCompanies[idx].filter(company => {
          return company.name === e
        })
        this.comparisonForm[idx].marketingCompanyDomain = companyObj[0]
      }
    },

    debounceAgencyName: _.debounce(function (val, idx) {
      if (val.length > 3) {
        this.comparisonForm[idx].marketingCompany = val
        this.fetchMarketingCompanies(val, idx)
      } else {
        this.filteredCompanies = []
      }
    }, 500),

    handleSearchInput (idx, evt) {
      this.companies = this.formData.marketingCompanies
      this.comparisonForm[idx].marketingCompany = evt
    },

    fetchMarketingCompanies (name, idx) {
      this.$arc.get('marketing_companies?per_page=500', {
        params: {
          ...(name ? { name_like: name } : {})
        }
      })
        .then(res => {
          this.$forceUpdate()
          this.filteredCompanies[idx] = res.data.marketing_companies
        })
        .catch(err => {
          console.error(err)
        })
    },

    groupArrayOfObjects (list, key) {
      return list.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
      }, {})
    },

    async handleIconUpload (id, evt) {
      const file = evt.target.files[0]
      if (!file) return

      try {
        const reader = new FileReader()

        reader.onload = (e) => {
          console.log(reader.result)
          const payload = {
            filename: file.name,
            b64hex_bin_data: this.getHexBinData(reader.result)
          }

          this.$arc.put(`marketing_companies/${id}/icon`, payload)
            .then(res => {
              const company = this.versusData.filter(comp => {
                return comp.id === id
              })
              company[0].profile.marketing_company.icon_url = res.data.icon_url
              this.getBaseLayoutAlert(this).showAlert('Successfully added agency icon', 'success')
              this.$forceUpdate()
            }).catch(error => {
              console.error('Error uploading icon', error)
              this.getBaseLayoutAlert(this).showAlert('Error adding agency icon', 'error')
            })
        }

        reader.readAsDataURL(file)
      } catch (error) {
        console.error('Error uploading icon', error)
      }
    },

    deleteCompanyIcon (id) {
      this.$arc.delete(`marketing_companies/${id}/icon`)
        .then(res => {
          const company = this.versusData.filter(comp => {
            return comp.id === id
          })
          company[0].profile.marketing_company.icon_url = res.data.icon_url
          this.$forceUpdate()
        })
        .catch(err => {
          console.error(err)
        })
    },

    toggleHeadToHead (e) {
      this.headToHeadFilters.showAllResults = e
    },

    getArcRank (sortedResults) {
      var self = this
      if (this.agencyScoresSort.sort.key === 'arc_score' && this.agencyScoresSort.sort.isAsc) {
        sortedResults.forEach((result, i) => {
          result.arc_rank = i + 1
          result.domain_slug = self.getDomainSlug(
            result.last_checked_at,
            result.location_name,
            result.practice_area_name,
            this.formatDomain(result.website),
            result.task_id
          )
        })
      }
    },

    filterTableData (idx, id, clear = false) {
      this.companyResults[idx].loading = true
      let domains = []

      if (this.formFilters[idx].showAllResults) {
        domains = _.cloneDeep(this.versusData[idx].profile.domains)
      } else {
        domains = _.cloneDeep(this.versusData[idx].profile.domains_not_agg)
      }

      if (!clear) {
        if (this.formFilters[idx].url) {
          const url = this.formFilters[idx].url.trim().toLowerCase()
          domains = domains.filter(domain => domain.website.toLowerCase().includes(url))
        }
        if (this.formFilters[idx].city) {
          const city = this.formFilters[idx].city.split(', ')[0].toLowerCase()
          domains = domains.filter(domain => domain.city.toLowerCase().includes(city))
        }
        if (this.formFilters[idx].tier) {
          const tier = this.formFilters[idx].tier
          domains = domains.filter(domain => domain.market_tier === tier)
        }
        if (this.formFilters[idx].practiceArea) {
          domains = domains.filter(domain => domain.practice_area_name.toLowerCase().includes(this.formFilters[idx].practiceArea.toLowerCase()))
        }
      }

      this.$set(this.companyResults[idx], 'domains', domains)

      this.getArcRank(this.sortedResults[idx])

      this.companyResults[idx].loading = false
    },

    clearFilters (idx, id) {
      this.$refs.tierDropdown[idx].selected = ''
      this.formFilters[idx].url = ''
      this.formFilters[idx].city = ''
      this.formFilters[idx].tier = ''
      this.formFilters[idx].practiceArea = ''
      this.filterTableData(idx, id, true)
    },

    clearHeadToHeadFilters () {
      this.headToHeadFilters.location = ''
      this.headToHeadFilters.practiceArea = ''
      this.headToHeadFilters.tier = undefined
      this.headToHeadFilters.showAllResults = false
    },

    sortedClass (obj, key) {
      return obj.sort.key === key
        ? `sorted ${obj.sort.isAsc ? 'asc' : 'desc'}`
        : ''
    },

    sortBy (obj, key) {
      obj.sort.isAsc = obj.sort.key === key ? !obj.sort.isAsc : false
      obj.sort.key = key
    },

    paginateData (items, currentPage, perPageItems) {
      const page = currentPage || 1
      const perPage = perPageItems || 25
      const offset = (page - 1) * perPage
      const paginatedItems = items.slice(offset).slice(0, perPageItems)
      const totalPages = Math.ceil(items.length / perPage)

      return {
        page: page,
        per_page: perPage,
        prev_page: page - 1 ? page - 1 : null,
        next_page: (totalPages > page) ? page + 1 : null,
        total: items.length,
        total_pages: totalPages,
        data: paginatedItems
      }
    },

    changeTablePage (idx, e) {
      this.pagination[idx].currentPage = e
    },

    changeTablePerPage (idx, e) {
      this.pagination[idx].perPage = e
    },

    toggleResults (idx, id, e) {
      this.formFilters[idx].showAllResults = e

      if (!e) {
        this.companyResults[idx].domains = this.versusData[idx].profile.domains_not_agg
      } else {
        this.companyResults[idx].domains = this.versusData[idx].profile.domains
      }
      this.getArcRank(this.sortedResults[idx])
      this.$forceUpdate()
      // this.getCompanyDomains(idx, id)
    },

    formatDomain (domain) {
      return domain.toString().includes('https://www.')
        ? domain.toString().replace('https://www.', '')
        : domain.toString().includes('http://')
          ? domain.toString().replace('http://', '')
          : domain.toString().replace('https://', '')
    },

    goToComparisonIndex () {
      this.$router.push({ path: 'agency-comparison' })
    },

    toggleAgencyAvgAll (evt) {
      this.agencyAvgAll = evt
    }
  }
}
</script>

<style lang="scss">
.comparison {
  .container {
    .page-header {
      margin-bottom: 140px;
    }
  }
  table {
    margin-bottom: 85px;
    tr {
      td {
        .checkmark {
          display: inline-block;
          width: 20px;
          height: 18px;
          margin: 0;
          margin-left: 10px;
        }
        strong {
          &.agency1, &.agency2 {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  #agency-profile {
    .button.is-view {
      margin: 0;
    }
  }
  #agency-client-scores {
    .tabs-header {
      .tab-heading {
        cursor: pointer;
        &:nth-child(2) {
          background: #F8572C;
        }
        &:nth-child(1) {
          background: #0F71F7;
        }
        &.selected {
          color: #FFF;
        }
      }
    }
  }
  #head-to-head {
    .flex + p {
      margin-bottom: 85px;
    }
    .logo-container {
      img {
        vertical-align: middle;
      }
    }

    .mkt-company-card {
      background: #fff;
      border: 1px solid #E0E9EF;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 15px;
      flex: 1 1 auto;
      max-width: calc(50% - 10px);
      padding: 1.5rem 1rem;

      > * {
        margin: 0;
      }

      .record {
        font-weight: 500;
        font-size: 15px;
      }

      .wins {
        display: block;
        font-size: 24px;
        line-height: 1;
        font-weight: 700;
      }

      .title {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 18px;
        font-weight: 600;

        img {
          margin: 0;
          max-height: 26px;
        }
      }
    }
  }
  #comparison-data {
    .logo-container {

      img {
        margin: 0 1rem 0 0;
        vertical-align: middle;
        display: inline-block;
      }
    }
    > .flex {
      flex-wrap: wrap;
      #toggle-action {
        justify-content: flex-start;
        margin-bottom: 0;
      }
      #comparison-action {
        justify-content: center;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 85px;
      }
      table {
        margin-bottom: 40px;
        + .action-links {
          width: 100%;
          justify-content: center;
          margin-bottom: 85px;
          margin-top: 0;
        }
      }
    }
  }
  #head-to-head,
  #agency-client-scores {
    .flex {
      margin-bottom: 40px;
      align-items: center;
    }
  }
  #agency-client-scores {
    table {
      margin-bottom: 0;
      margin-top: 25px;
    }
    form {
      margin-top: 40px;
      align-items: center;
      strong {
        margin-right: 10px;
      }
    }
    .action-links {
      justify-content: space-between;
    }
  }
  #head-to-head {
    form {
      align-items: center;
      strong {
        margin-right: 10px;
      }
      .dropdown-container {
        flex-basis: 100%;
      }
      + .action-links {
        justify-content: space-between;
      }
    }
  }
  #comparison-data {
    .flex {
      table {
        &:first-child {
          .score.large-text {
            color: #0F71F7;
            svg {
              path {
                fill: #0F71F7;
              }
            }
          }
        }
        &:nth-child(2) {
          .score.large-text {
            color: #F8572C;
            svg {
              path {
                fill: #F8572C;
              }
            }
          }
        }
      }
    }
  }
  .tab-content {
    .flex {
      margin-bottom: 20px !important;
    }
  }
  .table-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    .action-links {
      button {
        font-size: 15px;
      }
    }
  }
  .table-header {
    .logo-container {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      padding-bottom: 25px;
      img {
        max-width: 300px;
      }
      &:hover {
        .delete-img {
          opacity: 1;
        }
      }
      .delete-img {
        width: 20px;
        height: 20px;
        display: inline-flex;
        background: red;
        border-radius: 100%;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        color: #fff;
        position: absolute;
        top: 20px;
        right: -25px;
        opacity: 0;
        transition: all .3s ease-in-out;
        cursor: pointer;
      }
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    table {
      max-width: calc((100% / 2) - 15px);
      td {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        span {
          display: block;
          strong {
            &.large-text {
              font-size: 30px;
              line-height: 1;
              font-weight: 700;
              .checkmark {
                width: 30px;
                height: 25px;
                margin-left: 5px;
              }
            }
            &.winner {
              color: #0F71F7;
            }
          }
        }
        .cells {
          justify-content: flex-start;
          .cell {
            max-width: calc((100% / 4) - 5px);
            margin: 0 5px;
            width: 100%;
            &:first-child, &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }
  #comparison-form {
    .container {
      max-width: 800px;
      margin: 0 auto;
      .flex {
        margin-bottom: 30px;
        width: 100%;
        margin-right: 0;
      }
      form {
        flex-direction: column;
      }
    }
    .dropdown-container {
      flex-basis: 50%;
      margin: 0 10px;
    }
    button {
      margin: 0 auto;
    }
  }
}
</style>
