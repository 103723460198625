<template>
  <BaseLayout
    title="New Bulk A.R.C Reports"
    :customClasses="['bulk']"
    ref="baseLayout">
    <template v-slot:page-header>
      <action-links
        :noMargin="true"
        textAlign="right">
        <button
          @click="toggleCSVModal">
          <a>
            Upload CSV
          </a>
        </button>
        <!-- <Input
          type="file"
          :hasLabel="true"
          labelText="Upload csv"
          inputId="uploadCsv"
          @change="uploadFile($event)" /> -->
      </action-links>
    </template>
    <div class="bulk-forms">
      <BulkForm
        v-for="(form, index) in bulk.forms"
        :key="form.id"
        :id="form.id"
        :bulkForm="form"
        @minusClick="removeBulkForm(index)" />
    </div>
    <button-group
      btnAlign="right">
      <Button
        :hasIcon="true"
        icon="plus"
        type="button"
        text="New Row"
        :isInversed="true"
        :isBordered="true"
        @click="addBulkForm({
          location: '',
          practice_area: '',
          url: ''
        })" />
      <Button
        type="button"
        text="Enqueue Tasks"
        btnType="primary"
        @click="enqueueBulkSearch(bulk.forms)" />
    </button-group>
    <Modal
      ref="csvModal"
      :showModal="csvModalActive"
      :hasVerticalHeader="true"
      :hasAutoHeight="true"
      @close="closeModal">
      <template slot="header">
        <h1>Upload CSV File</h1>
        <ActionLinks>
          <a
            href="/bulk_example.csv"
            download="bulk_example.csv">
            Download CSV Template
          </a>
        </ActionLinks>
        <p style="margin-top:0">
          - Or -
        </p>
      </template>
      <template slot="body">
        <Form>
          <Input
            type="file"
            :hasLabel="true"
            labelText="Upload CSV"
            :fancyFile="true"
            @change="csvFile = $event"
            ref="csvInput" />
        </Form>
      </template>
      <template slot="footer">
        <ButtonGroup>
          <Button
            @click="saveFileDataAsRows(csvFile)"
            btnType="primary"
            text="Save" />
        </ButtonGroup>
      </template>
    </Modal>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/views/BaseLayout'
import Modal from '@/components/modal/Modal'
import BulkForm from '@/components/form/BulkForm'
import ActionLinks from '@/components/misc/ActionLinks'
import Button from '@/components/form/Button'
import ButtonGroup from '@/components/form/ButtonGroup'
import Form from '@/components/form/Form'
import Input from '@/components/form/Input'
const csv = require('csvtojson')

export default {
  name: 'ScoreBulk',
  components: {
    BaseLayout,
    BulkForm,
    ActionLinks,
    Button,
    ButtonGroup,
    Form,
    Modal,
    Input
  },
  data () {
    return {
      bulk: {
        forms: [],
        formCounter: 0
      },
      csvModalActive: false,
      csvFile: null
    }
  },
  mounted () {
    this.addBulkForm({
      dfs_location_name: '',
      practice_area_name: '',
      url: ''
    })
  },
  methods: {
    enqueueBulkSearch () {
      this.bulk.forms.forEach(form => {
        form.status = true
      })
    },

    toggleCSVModal () {
      this.csvModalActive = !this.csvModalActive
    },

    closeModal () {
      this.csvModalActive = false
    },

    addBulkForm (formData) {
      this.bulk.formCounter++
      this.bulk.forms.push({
        id: this.bulk.formCounter,
        form: BulkForm,
        data: formData,
        status: false
      })
    },

    removeBulkForm (index) {
      this.bulk.forms.splice(index, 1)
    },

    saveFileDataAsRows (e) {
      const file = e.target.files[0]
      const reader = new FileReader()

      reader.onload = e => {
        csv()
          .fromString(e.target.result)
          .then(arr => {
            arr.forEach(res => {
              // trim whitespace from all values
              Object.keys(res).forEach(key => {
                res[key] = res[key].trim()
              })
              const location = `${res.city},${res.state},${res.country}`
              const practiceArea = res.practice.toLowerCase()
              if (!res.url.startsWith('https://') && !res.url.startsWith('http://') && res.url !== '') {
                res.url = `https://${res.url}`
              }
              const row = {
                dfs_location_name: location,
                practice_area_name: practiceArea,
                url: res.url
              }
              this.addBulkForm(row)
            })
          })
      }
      reader.readAsText(file)
      this.removeBulkForm(0)
      this.closeModal()
      this.csvFile = null
    }
  }
}
</script>

<style lang="scss">

</style>
