<template>
  <td
    v-on="isEditable ?
      { dblclick: editing } : null"
    :class="{
      'is-editing': dropdownToggled,
      'text-center': textCentered,
      'no-decoration': noDecoration,
      'no-padding': noPadding
    }"
    :colspan="colspan"
    :data-content="item">
    <div
      class="icon is-danger"
      v-if="isEditable && edit"
      @click="cancelEdit">
      <font-awesome-icon
        icon="times" />
    </div>
    <div
      class="icon"
      v-if="isEditable && edit"
      @click="doneEditing">
      <font-awesome-icon
        icon="check" />
    </div>
    <Input
      v-if="edit && isEditable && isInput"
      ref="input"
      :placeholder="placeholder"
      @input="handleInput($event)"
      :value="inputVal"
      :isAbsolute="true" />
    <Dropdown
      v-if="edit && isEditable && !isInput"
      ref="dropdown"
      :options="dropdownOptions"
      :customOption="customOption"
      :isSearch="isDropdownSearch"
      @select="handleDropdownSelect($event)"
      @input="handleDropdownInput($event)"
      @dropdownActive="handleDropdownActive($event)"
      :inputVal="inputVal"
      :isAbsolute="true" />
    <span v-show="!edit">
      <slot></slot>
    </span>
  </td>
</template>

<script>
import Dropdown from '@/components/form/Dropdown'
import Input from '@/components/form/Input'

export default {
  name: 'TableDataCell',
  components: {
    Dropdown,
    Input
  },
  props: {
    isEditable: Boolean,
    textCentered: Boolean,
    noDecoration: Boolean,
    dropdownOptions: Array,
    customOption: String,
    defaultValue: [String, Number],
    colspan: String,
    isInput: {
      type: Boolean,
      default: false
    },
    isDropdownSearch: {
      type: Boolean,
      default: true
    },
    placeholder: [Number, String],
    item: {
      type: String,
      default: ''
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputVal: '',
      edit: false,
      dropdownToggled: false
    }
  },
  mounted () {
    if (this.defaultValue !== null) {
      this.inputVal = this.defaultValue
    }
  },
  watch: {
    defaultValue: {
      handler: function (val) {
        this.inputVal = val
      }
    }
  },
  methods: {
    log (e) {
      console.log(e)
    },
    selectDefault (e) {
    },
    cancelEdit () {
      this.$emit('cancelEdit')
      this.edit = false
      this.dropdownToggled = false
    },
    doneEditing () {
      this.edit = false
      this.dropdownToggled = false
      this.$emit('doneEditing', this.inputVal)
    },
    editing () {
      this.edit = true
      this.$nextTick(() => {
        if (!this.isInput) {
          this.$refs.dropdown.handleToggle()
        }
      })
    },
    handleDropdownSelect (evt) {
      this.inputVal = evt
      if (this.inputVal !== '') {
        this.toggleEdit()
      }
      this.$emit('dropdownSelect', evt)
    },
    handleDropdownActive (evt) {
      this.dropdownToggled = evt
    },
    handleDropdownInput (evt) {
      this.$emit('dropdownInput', evt)
      this.dropdownToggled = true
    },
    handleInput (evt) {
      this.$emit('editInput', evt)
    },
    toggleEdit () {
      this.dropdownToggled = !this.dropdownToggled
    }
  }
}
</script>

<style lang="scss">
tbody {
  tr {
    &:not(:first-of-type) {
      td {
        border-top: 1px solid #e4edf2;
      }
    }
  }
}
td {
  padding: 1.25rem;
  position: relative;
  transition: all .3s ease-in-out;
  height: 53px;
  max-width: 280px;
  word-wrap: break-word;
  &.green {
    background: #0F71F7;
  }
  &.purple {
    background: #F8572C;
  }
  &.grey {
    background: #efefef;
  }
  &.no-padding {
    padding: 0;
  }
  &.no-decoration {
    a {
      text-decoration: none;
      color: inherit;
    }
  }
  &.text-center {
    text-align: center;
    > span {
      display: flex;
      justify-content: center;
      .input-container {
        flex-basis: unset;
      }
    }
  }
  .cells {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    .cell {
      text-align: center;
      span {
        margin-bottom: 10px;
      }
    }
  }
  .dropdown-container.is-absolute {
    top: 4px;
    height: 70px;
  }
  &.is-editing {
    height: 150px;
    &.is-selected {
      height: 53px;
    }
    .dropdown-container.is-absolute {
      height: 53px;
    }
  }
  .icon {
    position: absolute;
    z-index: 999;
    top: 5px;
    right: 1px;
    font-size: 10px;
    background: #eee;
    padding: 3px 5px;
    cursor: pointer;
    svg {
      color: #212741;
    }
    &.is-danger {
      background: none;
      svg {
        color: #F70B14;
      }
    }
    &:first-child {
      right: 22px;
    }
  }
}
</style>
