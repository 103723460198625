<template>
  <BaseLayout
  title="Bulk ARC Export"
  :customClasses="['export']"
  ref="baseLayout">
    <Form @submit.prevent="getTasksForExport">
      <div class="flex" style="flex-wrap: nowrap">
        <Input
          placeholder="YYYY-MM-DD"
          :disabled="loading"
          :hasLabel="true"
          labelText="Date Range"
          v-model="form.dateFrom"
          :required="true"
          type="date"
        />
        <span style="margin: 15px 10px"> To </span>
        <Input
          placeholder="YYYY-MM-DD"
          :disabled="loading"
          v-model="form.dateTo"
          :required="true"
          type="date"
        />
      </div>
      <Dropdown
        :options="filteredLocations"
        :isSearch="true"
        dropdownPlaceholder="Start Typing Location"
        :inputHasLabel="true"
        inputId="city"
        inputLabelText="Location"
        ref="filterDropdown1"
        @select="form.location = $event"
        @input="debounceLocationName($event)"
        :inputVal="form.location"
        :isDisabled="loading"
        :hasClear="true"
        @clear="form.location = ''"
      />
      <Dropdown
        :hasIcon="true"
        :options="formData.practiceAreas"
        dropdownPlaceholder="Service Area"
        :inputHasLabel="true"
        inputId="practiceArea"
        inputLabelText="Service Area"
        ref="filterDropdown2"
        @select="form.practiceArea = $event"
        :isDisabled="loading"
        :hasClear="true"
        @clear="form.practiceArea = ''"
      />
      <Dropdown
        :options="form.tierOptions"
        :hasIcon="true"
        dropdownPlaceholder="Tier"
        ref="filterDropdown"
        @select="form.tier = $event"
        :isDisabled="loading"
        :inputHasLabel="true"
        inputLabelText="Market Tier"
        :hasClear="true"
        @clear="form.tier = ''"
      />
      <Button
        type="submit"
        text="Export"
        :isDisabled="loading" />
    </Form>
    <action-links textAlign="right" v-if="!loading">
      <Button @click="clearFilters" text="Clear filters" />
    </action-links>
    <Loading v-show="loading" text="Exporting as csv" />
  </BaseLayout>
</template>

<script>
import Dropdown from '@/components/form/Dropdown'
import BaseLayout from '@/views/BaseLayout'
import Button from '@/components/form/Button'
import Input from '@/components/form/Input'
import Loading from '@/components/misc/Loading'
import Form from '@/components/form/Form'
import { mapState } from 'vuex'
import ActionLinks from '@/components/misc/ActionLinks'
import _ from 'lodash'

export default {
  name: 'ScoreExport',
  components: {
    Dropdown,
    Input,
    Loading,
    Form,
    Button,
    ActionLinks,
    BaseLayout
  },
  data () {
    return {
      loading: false,
      sort: {
        key: 'completed_at',
        isAsc: false
      },
      form: {
        location: '',
        practiceArea: '',
        tierOptions: [1, 2, 3, 4, 5],
        tier: '',
        dateFrom: '',
        dateTo: ''
      },
      filteredLocations: []
    }
  },
  computed: {
    ...mapState({
      formData: (state) => state.form
    }),

    formattedLocation () {
      return this.form.location.replaceAll(', ', ',')
    },

    sortedResults () {
      const list = this.exportTasks.tasks ? this.exportTasks.tasks : []
      if (this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        })
      }
      return list
    }
  },
  methods: {
    debounceLocationName: _.debounce(function (val) {
      if (val.length > 2) {
        const cleanedVal = val.replace(/, /g, ',')
        this.getLocations(cleanedVal)
      } else {
        this.filteredLocations = []
      }
    }, 200),

    getLocations (name) {
      this.filteredLocations = this.searchUsLocationList(name).map((location) => {
        return location.replace(/,/g, ', ')
      })
    },

    sortedClass (key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : ''
    },

    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false
      this.sort.key = key
    },

    clearFilters () {
      var self = this
      Object.keys(this.form).forEach(function (key) {
        if (key !== 'tierOptions') {
          self.form[key] = ''
          return self.form
        }
      })
      this.$refs.filterDropdown.selected = ''
      this.$refs.filterDropdown1.selected = ''
      this.$refs.filterDropdown2.selected = ''
    },

    getTasksForExport () {
      this.loading = true
      const formatLocation = (location) => {
        if (!location) return ''
        const parts = location.split(', ')
        parts[0] = parts[0].trim().replace(/ /g, '%20')
        return parts.join(',').trim()
      }
      const params = {
        ...(this.form.dateFrom && { completed_at_min: this.form.dateFrom }),
        ...(this.form.dateTo && { completed_at_max: this.form.dateTo }),
        ...(this.form.location && { dfs_location_names: formatLocation(this.form.location) }),
        ...(this.form.practiceArea && { practice_area_names: this.form.practiceArea }),
        ...(this.form.tier && { market_tiers: this.form.tier })
      }

      const queryString = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&')

      this.$arc
        .get(`tasks/export?${queryString}`)
        .then((res) => {
          if (res.data.split('\n').length <= 2 && res.status === 200) {
            this.getBaseLayoutAlert(this).showAlert('No data found within parameters. Try adjusting filters.', 'warning')
            this.loading = false
            return
          }

          if (
            res.headers['content-type'] === 'text/csv' &&
            res.status === 200
          ) {
            try {
              const filename = `${this.form.dateFrom}--${this.form.dateTo}`
              const blob = new Blob([res.data], { type: 'text/csv;charset=utf-8;' })

              const url = window.URL.createObjectURL(blob)
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', `${filename}.csv`)

              document.body.appendChild(link)
              link.click()

              document.body.removeChild(link)
              window.URL.revokeObjectURL(url)

              this.getBaseLayoutAlert(this).showAlert('Successfully exported file', 'success')
            } catch (error) {
              this.getBaseLayoutAlert(this).showAlert('Error downloading csv file, please try again', 'error')
            }
          } else if (res.status === 202) {
            this.getBaseLayoutAlert(this).showAlert('A csv file will be sent to your email once it is ready', 'success')
          }

          this.loading = false
        })
        .catch((err) => {
          console.error(err)
          this.loading = false
        })
    }
  }
}
</script>

<style lang='scss'>
.export {
  form {
    align-items: flex-end;
    margin-bottom: 30px;
    justify-content: space-between;
    strong {
      margin-bottom: 1rem;
    }
    > div {
      flex-basis: calc((100% / 4) - 30px) !important;
      &.flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        label {
          font-weight: 600;
          margin-bottom: 10px;
          display: block;
          width: 100%;
        }
        .input-container {
          flex-basis: calc((100% / 2) - 7px);
        }
      }
    }
    strong {
      margin-right: 15px;
    }
  }
}
</style>
