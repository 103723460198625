<template>
  <nav>
    <div class="nav-start">
      <router-link
        :to="{ name: 'index' }">
        <img
          v-if="'logo' in companyInfo && companyInfo.logo"
          :src="require(`@/assets/img/${companyInfo.logo}`)">
      </router-link>
    </div>
    <div class="nav-end" v-if="!$auth.loading && $auth.isAuthenticated">
      <NavItem
        text="Home"
        :link="{ name: 'agencies' }" />
      <nav-item
        text="Power Rankings"
        :link="{}"
        :hasChildren="true">
        <sub-menu>
          <NavItem
            text="New Power Rankings Report"
            :link="{ path: '/power-rankings' }" />
          <NavItem
            text="Power Rankings Archives"
            :link="{ path: '/power-rankings/archives' }" />
          <NavItem
            text="Blacklist Domains"
            :link="{ path: '/power-rankings/blacklist' }" />
        </sub-menu>
      </nav-item>
      <nav-item
        text="ARC Reports"
        :link="{}"
        :hasChildren="true">
        <sub-menu>
          <NavItem
            text="New City ARC Report"
            :link="{ name: 'search' }" />
          <NavItem
            text="New Single ARC Report"
            :link="{ name: 'website' }" />
          <NavItem
            text="Bulk ARC Report"
            :link="{ name: 'bulk' }" />
          <NavItem
            text="Schedule ARC Report"
            :link="{ name: 'schedule' }" />
          <NavItem
            text="ARC Report Archives"
            :link="{ name: 'archives' }" />
          <NavItem
            text="Bulk ARC Export"
            :link="{ name: 'export' }" />
        </sub-menu>
      </nav-item>
      <nav-item
        text="Agency Information"
        :link="{}"
        :hasChildren="true">
        <sub-menu>
          <NavItem
            text="Agency Scores"
            :link="{ name: 'agencies' }" />
          <NavItem
            text="Agency Comparison"
            :link="{ name: 'agency-comparison' }" />
          <NavItem
            text="Agency Changes"
            :link="{ name: 'agency-changes' }" />
          <NavItem
            text="Website &amp; Agency"
            :link="{ name: 'web-agency' }" />
        </sub-menu>
      </nav-item>
      <nav-item
        text="Site Information"
        :link="{}"
        :hasChildren="true">
        <sub-menu>
          <NavItem
            text="Site History"
            :link="{ name: 'site-history' }" />
          <NavItem
            text="Site Status"
            :link="{ name: 'status-changes' }" />
          <NavItem
            text="Domain Redirects"
            :link="{ name: 'domain-redirects' }" />
        </sub-menu>
      </nav-item>
      <nav-item
        v-if="$auth.isAuthenticated"
        :hasLink="false"
        :hasImage="true"
        :hasChildren="true">
        <a>
          <img
            v-if="profilePicture"
            :src="profilePicture"
            class="nav-profile">
          <img
            :src="$auth.user.picture"
            class="nav-profile"
            v-else>
        </a>
        <sub-menu>
          <NavItem
            text="Settings"
            :link="{ name: 'user-settings' }" />
          <nav-item
            :hasLink="false"
            @click="logout">
            Logout
          </nav-item>
        </sub-menu>
      </nav-item>
    </div>
    <div class="nav-end" v-if="!$auth.loading && !this.$auth.isAuthenticated">
      <div
        class="calendly-cta"
        v-if="footerInfo && footerInfo.calendly_link">
        <a
          :href="footerInfo.calendly_link"
          class="button calendly-link is-fancy"
          target="_blank">
          Click to book a consultation with me
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import NavItem from '@/components/nav/NavItem'
import NavSubMenu from '@/components/nav/NavSubMenu'
import Cookies from 'js-cookie'
import { mapGetters } from 'vuex'

export default {
  name: 'Nav',
  components: {
    NavItem,
    'sub-menu': NavSubMenu
  },
  computed: {
    ...mapGetters({
      profile: 'auth/getProfile',
      profilePicture: 'auth/getProfilePicture',
      footerInfo: 'footer/getUserInfo'
    }),

    baseUrl () {
      return process.env.VUE_APP_BASE_URL
    },

    userEmail () {
      return this.footerInfo && this.footerInfo.email
        ? this.footerInfo.email
        : this.profile && this.profile.email
          ? this.profile.email
          : null
    },

    companyLogo () {
      return this.userEmail ? this.getCompanyLogo(this.userEmail) : null
    },

    companyInfo () {
      return this.getCompanyInfo()
    }
  },
  mounted () {
  },
  methods: {
    logout () {
      Cookies.remove('_.arc_score.arcScoreKey')
      Cookies.remove('_.arc_score.salesOutreachKey')
      Cookies.remove('_.arc_score.arcScoreViewKey')
      this.$store.dispatch('auth/setProfile', null)
      this.$store.dispatch('auth/setProfilePicture', null)
      this.$store.dispatch('form/clearData')
      this.$auth.logout({
        logoutParams: {
          returnTo: this.baseUrl
        }
      })
    }
  }
}
</script>

<style lang="scss">
nav {
  background: #0158E3;
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  height: 80px;
  .nav {
    &-profile {
      max-height: 35px;
      max-width: 35px;
      border-radius: 100%;
    }
    &-start {
      max-width: 220px;
      display: flex;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 50px;
      }
    }
    &-end {
      display: flex;
      align-items: center;

      .calendly-cta {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .calendly-link {
        background: #fbc617;
        color: #012C72;
        letter-spacing: 0.025em;
        text-decoration: none;

        &:hover {
          color: #fff;
          background: #012C72;
          &::before {
            opacity: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  nav {
    flex-direction: column;
    align-items: center;
    padding: 30px 0 0;
    height: auto;
    gap: 20px;
  }
}
</style>
