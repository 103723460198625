<template>
  <div
    class="input-container"
    :class="{
      'has-button': hasButton,
      'has-label': hasLabel,
      'is-file-input': type === 'file',
      'is-checkbox-input': type === 'checkbox',
      'is-fancy-file': fancyFile
    }">
    <label
      :for="inputId"
      v-if="hasLabel"
      @click="$emit('labelClick', $event)"
      :class="{
        'is-checked': checked
      }"
      :style="customLabelStyle">
      {{ labelText }}
      <span v-if="required">*</span>
    </label>
    <input
      class="input"
      :type="type"
      :value="value"
      :readonly="readonly"
      :id="inputId"
      :style="[
        readonly
        ? { 'cursor': 'pointer' }
        : ''
      ]"
      :class="{
        'no-padding': noPadding,
        'is-absolute': isAbsolute
      }"
      :disabled="disabled"
      :placeholder="placeholder"
      :checked="checked"
      :min="min"
      :max="max"
      autocomplete="off"
      :required="required"
      :pattern="pattern"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur', $event)"
      @focus="$emit('focus', $event)"
      @change="$emit('change', $event)"
      @click="$emit('click', $event)">
    <Button
      v-if="hasButton"
      :text="btnText"
      :hasIcon="btnHasIcon"
      :icon="btnIcon"
      :isInversed="btnIsInversed"
      @click="$emit('btnClick', $event)" />
    <span class="error-message">
      This field is required.
    </span>
  </div>
</template>

<script>
import Button from '@/components/form/Button'

export default {
  name: 'Input',
  components: {
    Button
  },
  props: {
    type: String,
    value: [String, Number],
    disabled: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    inputId: String,
    labelText: String,
    hasLabel: {
      type: Boolean,
      default: false
    },
    hasButton: {
      type: Boolean,
      default: false
    },
    btnText: String,
    btnHasIcon: {
      type: Boolean,
      default: false
    },
    btnIsInversed: {
      type: Boolean,
      default: false
    },
    btnIcon: String,
    readonly: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    isAbsolute: {
      type: Boolean,
      default: false
    },
    fancyFile: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    min: String,
    max: String,
    customLabelStyle: [Object, Array],
    pattern: String
  }
}
</script>

<style lang="scss">
.input {
  border: 1px solid #B6C3CB;
  background: #fff;
  border-radius: 3px;
  padding: 1rem 1.5rem;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  color: inherit;
  max-height: 53px;
  font-family: inherit;
  &::placeholder {
    font-weight: 500;
    color: #b6bfc5;
    opacity: 1;
  }
  &:focus {
    outline: 0;
  }
  &-container {
    flex-basis: 280px;
    &.is-checkbox-input {
      position: relative;
      input {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
        cursor: pointer;
      }
      label {
        width: 20px;
        height: 20px;
        margin-bottom: 0 !important;
        border-radius: 100%;
        background: #eee;
        position: relative;
        display: flex !important;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.is-checked {
          background: #2cca6e;
          &:before {
            background: none;
            width: 5px;
            height: 8px;
            transform: rotate(45deg);
            border-bottom: 2px solid #fff;
            border-right: 2px solid #fff;
            margin-top: -1px;
          }
          &:after {
            display: none;
          }
        }
        &:before, &:after {
          content: '';
          display: block;
          background: #2c3e50;
          position: absolute;
          width: 0.12em;
          height: 8px;
          transform-origin: center;
          transform: rotate(-45deg);
        }
        &:after {
          transform: rotate(45deg);
        }
      }
    }
    &.is-file-input {
      position: relative;
      width: 280px;
      label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        color: #3b80e8;
        text-decoration: underline;
        cursor: pointer;
      }
      input {
        width: 0;
        height: 0;
        padding: 0;
      }

      &.is-fancy-file {
        label {
          position: relative;
          top: unset;
          right: unset;
          bottom: unset;
          left: unset;
          color: inherit;
          height: auto;
          text-decoration: none;
        }

        input {
          width: 100%;
          height: auto !important;
          padding: 12px;
        }
      }
    }
    &.has-label {
      label {
        font-weight: 700;
        margin-bottom: 7px;
        display: block;
        span {
          color: red;
          font-weight: 700;
        }
      }
      + .dropdown-select {
        top: 85px;
      }
    }
    &.has-button {
      max-width: 320px;
      position: relative;
      margin: 40px auto 0;
      .button {
        position: absolute;
        top: 5px;
        right: 5px;
        bottom: 5px;
        font-size: 14px;
        padding: 0 1rem;
      }
      input {
        font-size: 14px;
        padding: .5rem 1rem;
      }
    }
    input {
      &[disabled="disabled"] {
        opacity: .4;
        cursor: not-allowed !important;
      }
    }
    .error-message {
      display: none;
      color: red;
      font-weight: 700;
      font-size: 12px;
      position: absolute;
      bottom: -18px;
    }
    &.error {
      position: relative;
      input {
        border-color: red;
      }
      label {
        color: red;
      }
      .error-message {
        display: block;
      }
    }
  }
  &.no-padding {
    padding: 0;
  }
  &.is-absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    padding: .75rem;
    max-height: unset;
  }
}
</style>
