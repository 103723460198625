var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"dropdown",staticClass:"dropdown-container",class:{
    'has-icon': _vm.hasIcon,
    'is-disabled': _vm.isDisabled,
    'is-absolute': _vm.isAbsolute,
    'is-create-mode': _vm.createOption,
    'has-clear': _vm.hasClear
  }},[(_vm.hasClear)?_c('span',{staticClass:"dropdown-clear",on:{"click":function($event){return _vm.handleClear($event)}}},[_vm._v(" clear ")]):_vm._e(),_c('Input',{ref:"input",attrs:{"type":"text","readonly":!_vm.isSearch,"placeholder":_vm.dropdownPlaceholder,"disabled":_vm.isDisabled,"hasLabel":_vm.inputHasLabel,"inputId":_vm.inputId,"labelText":_vm.inputLabelText,"value":_vm.inputVal,"required":_vm.inputRequired},on:{"click":function($event){return _vm.handleToggle($event)},"input":function($event){return _vm.handleInput($event)}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive),expression:"isActive"}],staticClass:"dropdown-select"},[_vm._l((_vm.options),function(option,i){return _c('li',{key:i,on:{"mousedown":function($event){$event.preventDefault();return _vm.select(
        _vm.customOption && _vm.secondOption
          ? option[_vm.customOption] + ' ' + option[_vm.secondOption]
          : _vm.customOption
            ? option[_vm.customOption]
            : option
      )}}},[_vm._v(" "+_vm._s(_vm.customOption && _vm.secondOption ? option[_vm.customOption] + ' ' + option[_vm.secondOption] : _vm.customOption ? option[_vm.customOption] : _vm.displayOption ? option[_vm.displayOption] : option)+" ")])}),(_vm.options.length === 0)?_c('li',[_vm._v(" "+_vm._s(_vm.emptyResultsMessage ? _vm.emptyResultsMessage : 'No results')+" ")]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }