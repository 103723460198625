<template>
  <div
    class="dropdown-container"
    :class="{
      'has-icon': hasIcon,
      'is-disabled': isDisabled,
      'is-absolute': isAbsolute,
      'is-create-mode': createOption,
      'has-clear': hasClear
    }"
    ref="dropdown">
    <span
      class="dropdown-clear"
      v-if="hasClear"
      @click="handleClear($event)">
      clear
    </span>
    <Input
      type="text"
      :readonly="!isSearch"
      ref="input"
      :placeholder="dropdownPlaceholder"
      :disabled="isDisabled"
      :hasLabel="inputHasLabel"
      :inputId="inputId"
      :labelText="inputLabelText"
      :value="inputVal"
      :required="inputRequired"
      @click="handleToggle($event)"
      @input="handleInput($event)"
      v-model="selected" />
    <ul
      class="dropdown-select"
      v-show="isActive">
      <li
        v-for="(option, i) in options"
        :key="i"
        @mousedown.prevent="select(
          customOption && secondOption
            ? option[customOption] + ' ' + option[secondOption]
            : customOption
              ? option[customOption]
              : option
        )">
        {{
          customOption && secondOption
            ? option[customOption] + ' ' + option[secondOption]
            : customOption
              ? option[customOption]
              : displayOption ? option[displayOption] : option
        }}
      </li>
      <li v-if="options.length === 0">
        {{ emptyResultsMessage ? emptyResultsMessage : 'No results' }}
      </li>
      <!-- <li
        v-if="createOption"
        @click="handleCreateOptionClick($event)">
        <span class="icon">
          <font-awesome-icon
            icon="plus" />
        </span>
        {{ createOptionMessage }}
      </li> -->
    </ul>
  </div>
</template>

<script>
import Input from '@/components/form/Input'

export default {
  name: 'Dropdown',
  components: {
    Input
  },
  data () {
    return {
      selected: this.defaultValue !== '' ? this.defaultValue : '',
      isActive: false
    }
  },
  props: {
    inputHasLabel: Boolean,
    inputId: String,
    inputLabelText: String,
    inputVal: [Number, String],
    isAbsolute: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    hasIcon: {
      type: Boolean,
      default: false
    },
    hasClear: {
      type: Boolean,
      default: false
    },
    inputRequired: {
      type: Boolean,
      default: false
    },
    customOption: String,
    secondOption: String,
    displayOption: String,
    dropdownPlaceholder: String,
    emptyResultsMessage: String,
    isDisabled: {
      type: Boolean,
      default: false
    },
    defaultValue: [String, Number],
    createOption: {
      type: Boolean,
      default: false
    },
    createOptionMessage: {
      type: String,
      default: ''
    }
  },
  watch: {
    inputVal: {
      handler: function (val) {
        this.selected = val
      },
      immediate: true
    },
    isActive: {
      handler: function (val) {
        this.$emit('dropdownActive', val)
      },
      immediate: true
    }
  },
  mounted () {
    if (this.defaultValue !== undefined) {
      this.selected = this.defaultValue
      this.$emit('select', this.selected)
    }
  },
  created () {
    window.addEventListener('click', this.close)
  },
  beforeDestroy () {
    window.removeEventListener('click', this.close)
  },
  methods: {
    select (item) {
      this.selected = item
      this.isActive = false
      this.$emit('select', item)
    },
    handleToggle (evt) {
      this.$emit('dropdownToggled', evt)
      this.isActive = !this.isActive
      if (this.isActive) {
        this.$refs.input.$el.getElementsByTagName('input')[0].focus()
      }
    },
    handleInput (e) {
      this.$emit('input', e)
      if (this.isSearch) {
        this.isActive = true
      }
    },
    handleClear (e) {
      this.$emit('clear', e)
      this.selected = undefined
    },
    close (e) {
      if (!this.$refs.dropdown.contains(e.target)) {
        this.isActive = false
      }
    },
    handleCreateOptionClick (e) {
      this.$emit('createOptionClick', e)
      this.select(this.$refs.input.value)
    }
  }
}
</script>

<style lang="scss">
.dropdown-container {
  position: relative;
  z-index: 99;
  flex-basis: 280px;
  &.is-absolute {
    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 53px;
    .dropdown-select {
      height: 90px;
      top: 100%;
      li:last-child {
        border-bottom: 1px solid #e4edf2;
      }
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      max-height: unset;
    }
  }
  &.is-disabled {
    &:after {
      opacity: .4;
    }
  }
  &.has-icon {
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-top: 7px solid #EE6152;
      border-right: 5px solid transparent;
      border-bottom: 5px solid transparent;
      position: absolute;
      bottom: calc(1rem * 1.5 - 6px);
      right: 1.25rem;
    }
    input {
      padding: 1rem 2.5rem 1rem 1.25rem;
    }
  }
  &.has-clear {
    .dropdown-clear {
      position: absolute;
      top: 4px;
      right: 6px;
      font-size: 12px;
      color: #EE6152;
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  input {
    padding: 1rem 1.25rem;
  }
  .dropdown {
    &-select {
      background: #fff;
      width: 100%;
      padding: 0;
      border: 1px solid #e4edf2;
      border-radius: 6px;
      position: absolute;
      top: 50px;
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      li {
        padding: 1rem 1.5rem;
        font-weight: 500;
        cursor: pointer;
        &:not(:last-child) {
          border-bottom: 1px solid #e4edf2;
        }
        &:hover {
          background: #f6f8fc;
        }
      }
    }
  }
}
</style>
