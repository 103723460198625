<template>
  <div
    class="modal"
    v-show="showModal">
    <div
      class="modal-card"
      :class="{ 'is-auto-height': hasAutoHeight }">
      <div
        class="modal-card-header"
        :class="{ 'is-vertical': hasVerticalHeader }"
        v-if="hasHeaderSlot">
        <button
          @click="close($event)">
          <span></span>
          <span></span>
        </button>
        <slot name="header"></slot>
      </div>
      <div
        class="modal-card-body"
        v-if="hasBodySlot">
        <slot name="body"></slot>
      </div>
      <div
        class="modal-card-footer"
        v-if="hasFooterSlot">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    hasVerticalHeader: {
      type: Boolean,
      default: false
    },
    hasAutoHeight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots.header
    },

    hasFooterSlot () {
      return !!this.$slots.footer
    },

    hasBodySlot () {
      return !!this.$slots.body
    }
  },
  methods: {
    close (e) {
      this.$emit('close', e)
    }
  }
}
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  width: 100%;
  height: 100%;
  &-card {
    background: #fff;
    border: 1px solid #e0e9ef;
    border-radius: 10px;
    max-width: 814px;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 55vh;
    max-height: 85vh;
    overflow-y: auto;
    &.is-auto-height {
      height: auto;
      min-height: unset;
    }
    &-footer {
      display: flex;
      justify-content: center;
      padding: 3rem 3rem 4.62rem;
      z-index: 0;
      button {
        margin-bottom: 0 !important;
      }
    }
    &-header {
      display: flex;
      justify-content: center;
      padding: 4.625rem 3rem 1rem;
      position: relative;

      &.is-vertical {
        flex-direction: column;
        align-items: center;
      }
      button {
        margin-bottom: 0;
        position: absolute;
        top: 2rem;
        right: 1.5rem;
        font-size: 18px;
        border: none;
        background: none;
        cursor: pointer;
        span {
          width: 22px;
          height: 1px;
          display: block;
          background: #A4AFB5;
          transform-origin: center;
          &:first-child {
            transform: rotate(-45deg);
          }
          &:last-child {
            transform: rotate(45deg);
            margin-bottom: 1px;
          }
        }
      }
    }
    &-body {
      // padding: 0 3rem;
      max-width: 625px;
      margin: 0 auto;
      width: 100%;
      .dropdown-container {
        z-index: unset;
        .dropdown-select {
          z-index: 1;
        }
      }
      > .flex {
        align-items: flex-end;
        margin: 20px 0 0 !important;
      }
      .flex {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-basis: 100% !important;
        margin: 0;
        flex-wrap: wrap;
        gap: 20px 7.5px;
        button {
          margin-top: 50px;
        }
        &-clear {
          width: 100%;
          flex: 1;
          min-width: 100%;
        }
        > .left, > .right {
          width: calc((100% / 2) - calc(15px/2));
          > div:not(:first-child) {
            margin-top: 20px;
          }
        }
        .left {
          .flex {
            justify-content: space-between;
            .input-container {
              width: calc((100% / 2) - 5px);
              flex-basis: unset;
              &:last-child {
                margin-top: 30px;
              }
            }
          }
        }
        .right {
          > .input-container {
            margin-top: 0;
          }
        }
      }
      .multiselect {
        & .multiselect {
          &__tags {
            padding: 1rem 2.5rem 1rem 1.25rem;
            border: 1px solid #B6C3CB;
            border-radius: 3px;
          }
          &__tag {
            background: #0158E3;
            &-icon {
              &:after {
                color: #fff;
              }
              &:focus,
              &:hover {
                background: #063991;
                border-radius: 0;
              }
            }
          }
          &--active {
            .multiselect__select {
              :before {
                right: 1.25rem;
              }
            }
          }
          &__placeholder {
            margin: 0;
            padding: 0;
          }
          &__select {
            top: 0;
            bottom: 0;
            margin: auto;
            &:before {
              content: "";
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-top: 7px solid #EE6152;
              border-right: 5px solid transparent;
              border-bottom: 5px solid transparent;
              position: absolute;
              top: 12px;
              right: 1.25rem;
            }
          }
          &__option {
            &--highlight {
              background: #0158E3;
              &:after {
                background: #0158E3;
              }
            }
            &--selected {
              &.multiselect__option--highlight {
                background: #0158E3;
                &:after {
                  background: #0158E3;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
