<template>
  <div class="page-content">
    <Loading
      v-show="loading"
      text="Loading profile" />
    <settings-panel
      :hasActionLink="false">
      <div class="settings-card">
        <div class="avatar-field">
          <div class="avatar-image">
            <img
              v-if="profile && profile.profile_picture_url"
              :src="profile.profile_picture_url"
              class="avatar" />
            <img
              :src="$auth.user.picture"
              class="nav-profile"
              v-else>
            <input
              ref="avatarInput"
              type="file"
              @change="handleAvatarUpload($event)" />
          </div>
          <div class="avatar-actions">
            <Button
              btnType="primary"
              text="Change"
              :hasIcon="true"
              icon="image"
              :iconReversed="true"
              @click="$refs.avatarInput.click()" />
            <Button
              text="Remove"
              btnType="primary"
              :hasIcon="true"
              icon="trash"
              :iconReversed="true"
              :isInversed="true"
              :isBordered="true"
              @click="removeProfilePicture" />
          </div>
        </div>
        <Form
          :isGrid="true"
          :rowGap="20">
          <Input
            type="text"
            placeholder="Full Name"
            :hasLabel="true"
            labelText="Full Name"
            v-model="form.fullName" />
          <Input
            type="email"
            placeholder="Email Address"
            :hasLabel="true"
            labelText="Email Address"
            v-model="form.email" />
          <Input
            type="tel"
            placeholder="Phone Number"
            :hasLabel="true"
            labelText="Phone Number"
            v-model="form.phone"
            :inputValue="form.phone"
            @input="handlePhoneInput($event)" />
          <Input
            type="url"
            placeholder="Calendly Link"
            :hasLabel="true"
            labelText="Calendly Link"
            v-model="form.calendlyLink" />
          <Input
            type="text"
            placeholder="Job Title"
            :hasLabel="true"
            labelText="Job Title"
            v-model="form.jobTitle" />
        </Form>
        <ButtonGroup>
          <Button
            btnType="primary"
            text="Save"
            @click="updateUserData"
            :isDisabled="loading" />
        </ButtonGroup>
      </div>
    </settings-panel>
  </div>
</template>

<script>
import Loading from '@/components/misc/Loading'
import Button from '@/components/form/Button'
import ButtonGroup from '@/components/form/ButtonGroup'
import Form from '@/components/form/Form'
import Input from '@/components/form/Input'
import SettingsPanel from '@/components/global/user/SettingsPanel'
import { mapGetters } from 'vuex'

export default {
  name: 'UserProfile',
  components: {
    Loading,
    Button,
    ButtonGroup,
    Form,
    Input,
    SettingsPanel
  },
  data () {
    return {
      loading: false,
      phoneInput: '',
      form: {
        fullName: '',
        email: '',
        phone: '',
        calendlyLink: '',
        jobTitle: '',
        profilePictureFname: '',
        profilePictureB32HexData: '',
        profilePicturePayload: {}
      }
    }
  },
  computed: {
    ...mapGetters({
      profile: 'auth/getProfile'
    })
  },
  mounted () {
    this.setProfile()
  },
  methods: {
    setProfile () {
      if (this.profile) {
        this.setProfileForm(this.profile)
      }
    },

    setProfileForm (user) {
      this.form.fullName = user.name
      this.form.email = user.email
      this.form.phone = user.phone
      this.form.calendlyLink = user.calendly_link
      this.form.jobTitle = user.job_title
    },

    async updateUserData () {
      const params = {
        ...(this.form.fullName !== this.profile.name && { name: this.form.fullName !== '' ? this.form.fullName : null }),
        ...(this.form.email !== this.profile.email && { email: this.form.email !== '' ? this.form.email : null }),
        ...(this.form.phone !== this.profile.phone && { phone: this.form.phone !== '' ? this.form.phone : null }),
        ...(this.form.calendlyLink !== this.profile.calendly_link && { calendly_link: this.form.calendlyLink !== '' ? this.form.calendlyLink : null }),
        ...(this.form.jobTitle !== this.profile.job_title && { job_title: this.form.jobTitle !== '' ? this.form.jobTitle : null }),
        ...(this.form.profilePicturePayload.filename && { profile_pic_fname: this.form.profilePicturePayload.filename }),
        ...(this.form.profilePicturePayload.b64hex_bin_data && { profile_pic_b64hex_bin_data: this.form.profilePicturePayload.b64hex_bin_data })
      }
      if (Object.keys(params).length === 0) {
        this.getBaseLayoutAlert(this.$parent).showAlert('No changes detected', 'info')
        return
      }

      if (params.calendly_link && !params.calendly_link.includes('https://calendly.com/')) {
        this.getBaseLayoutAlert(this.$parent).showAlert('Invalid Calendly link, must start with https://calendly.com/', 'error')
        return
      }

      try {
        this.loading = true
        const { data: user } = await this.$arc.patch('users/me', params)
        this.getBaseLayoutAlert(this.$parent).showAlert('Profile updated successfully', 'success')
        this.$store.dispatch('auth/setProfile', user)
      } catch (err) {
        this.loading = false
        console.error(err)
        this.getBaseLayoutAlert(this.$parent).showAlert('Failed to update profile', 'error')
      } finally {
        this.loading = false
      }
    },

    handlePhoneInput (evt) {
      let inputNumbersValue = evt.replace(/\D/g, '')

      if (inputNumbersValue.length > 10) {
        inputNumbersValue = inputNumbersValue.slice(0, 10)
      }

      if (inputNumbersValue.length < 4) {
        this.form.phone = inputNumbersValue
      } else if (inputNumbersValue.length < 7) {
        this.form.phone = `(${inputNumbersValue.substring(0, 3)}) ${inputNumbersValue.substring(3)}`
      } else {
        this.form.phone = `(${inputNumbersValue.substring(0, 3)}) ${inputNumbersValue.substring(3, 6)}-${inputNumbersValue.substring(6, 10)}`
      }
    },

    async removeProfilePicture () {
      try {
        this.loading = true
        const { data: user } = await this.$arc.patch('users/me', {
          profile_pic_fname: null,
          profile_pic_b64hex_bin_data: null,
          profile_pic_sha256_hex_digest: null
        })
        this.getBaseLayoutAlert(this.$parent).showAlert('Profile picture removed successfully', 'success')
        this.$store.dispatch('auth/setProfile', user)
        this.form.profilePictureFname = ''
        this.form.profilePictureB32HexData = ''
      } catch (err) {
        this.loading = false
        console.error(err)
        this.getBaseLayoutAlert(this.$parent).showAlert('Failed to remove profile picture', 'error')
      } finally {
        this.loading = false
      }
    },

    async handleAvatarUpload (evt) {
      const file = evt.target.files[0]
      if (!file) {
        console.error('No file selected.')
        return
      }
      this.profile.profile_picture_url = URL.createObjectURL(file)

      const reader = new FileReader()

      reader.onload = (event) => {
        this.form.profilePicturePayload = {
          filename: file.name,
          b64hex_bin_data: this.getHexBinData(reader.result)
        }
      }

      reader.readAsDataURL(file)
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-card {
  .button {
    margin-top: 30px;
  }

  .avatar-field {
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    .avatar-image {
      width: 150px;
      height: 150px;
      border-radius: 100%;
      overflow: hidden;
      position: relative;
      z-index: 1;

      &::after {
        content: 'Edit';
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all .3s ease-in-out;
        cursor: pointer;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }

      img {
        margin: 0;
        object-position: center center;
        width: 100%;
        height: 100%;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
      }
    }
    .avatar-actions {
      display: flex;
      flex-direction: column;
      gap: 10px;
      button {
        margin: 0;
        width: 100%;
        padding: .85rem 1rem;
      }
    }
  }
}
</style>
