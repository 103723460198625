<template>
  <div
    class="accordion"
    :class="{
      'open': isActive
    }">
    <span
      class="accordion-title"
      @click="toggleAccordion">
      {{ title }}
    </span>
    <div
      class="accordion-content"
      v-show="isActive">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isActive: false
    }
  },
  methods: {
    toggleAccordion () {
      this.isActive = !this.isActive
    }
  }
}
</script>

<style lang="scss">
.accordion {
  width: 100%;
  border-radius: 7px;
  border: 1px solid #E0E9EF;
  background: #fff;

  &s {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  &-title {
    padding: 1.75rem 2rem;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-top: 7px solid #E0E9EF;
      border-left: 6px solid transparent;
      border-bottom: 0;
      border-right: 6px solid transparent;
    }
  }

  &.open {
    .accordion-title {
      border-bottom: 1px solid #E0E9EF;
    }
  }

  &-content {
    padding: 1.75rem 2rem;

    ul {
      position: relative;

      &::before {
        content: '';
        width: 1px;
        height: calc(100% - 16px);
        background: #E0E9EF;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      li {
        position: relative;
        padding-left: 1.25rem;

        &:not(:last-child) {
          padding-bottom: 9px;
        }

        &::before {
          content: '';
          width: 6px;
          height: 6px;
          background: #E0E9EF;
          border-radius: 100%;
          left: -2.5px;
          position: absolute;
          display: block;
          top: 8px;
        }
      }
    }
  }
}
</style>
