<template>
  <Modal
    :showModal="isActive"
    @close="closeModal"
    class="outreach-modal">
    <template slot="header">
      <h2>{{ mode === 'create' ? 'Add new prospect' : 'Edit Prospect' }}</h2>
    </template>
    <template slot="body">
      <Form
        ref="outreachForm"
        v-if="mode === 'create'"
        @submit.prevent="createOutreachProspect(
          outreach.name,
          outreach.firstName,
          outreach.lastName,
          outreach.ownerId,
          outreach.website,
          outreach.businessTypeId,
          outreach.address,
          outreach.tags,
          outreach.notes
        )">
        <div class="flex">
          <div class="left">
            <Dropdown
              dropdownPlaceholder="Business Name"
              :inputHasLabel="true"
              inputLabelText="Business Name"
              :options="outreachCompanies"
              customOption="name"
              :isSearch="true"
              :inputRequired="true"
              @select="outreach.name = $event"
              @input="searchOutreachCompanies($event)"
              :inputVal="outreach.name" />
            <div>
              <Dropdown
                :options="filteredStates"
                :isSearch="true"
                dropdownPlaceholder="State"
                :inputHasLabel="true"
                inputId="state"
                inputLabelText="State"
                ref="stateDropdown"
                @select="outreach.address.state = $event; outreach.address.city=''"
                @input="outreach.address.state = $event; outreach.address.city=''"
                :inputVal="outreach.address.state"
              />
            </div>
            <div class="flex">
              <Input
                placeholder="First Name"
                :hasLabel="true"
                labelText="Contact"
                v-model="outreach.firstName" />
              <Input
                placeholder="Last Name"
                v-model="outreach.lastName" />
            </div>
            <Dropdown
              dropdownPlaceholder="Owner"
              :hasIcon="true"
              :inputHasLabel="true"
              inputLabelText="Owner"
              :options="outreach.owners"
              customOption="first_name"
              secondOption="last_name"
              :inputVal="outreach.ownerId ? outreach.owner : ''"
              :inputRequired="true"
              @select="outreach.owner ? handleOutreachOwner($event) : ''" />
          </div>
          <div class="right">
            <Input
              placeholder="Website"
              :hasLabel="true"
              labelText="Website"
              :required="true"
              v-model="outreach.website" />
            <Dropdown
              :options="filteredCities"
              :isSearch="true"
              :dropdownPlaceholder="outreach.address.state==='' ? 'Select a state' : 'City'"
              :inputHasLabel="true"
              inputId="city"
              inputLabelText="City"
              ref="cityDropdown"
              :isDisabled="outreach.address.state === ''"
              @select="outreach.address.city = $event;"
              @input="outreach.address.city = $event;"
              :inputVal="outreach.address.city"
              />
            <Dropdown
              dropdownPlaceholder="Business Type"
              :hasIcon="true"
              :inputHasLabel="true"
              inputLabelText="Business Type"
              :options="outreach.businessTypes"
              customOption="name"
              ref="businessTypeDropdown"
              :defaultValue="outreach.businessType"
              @select="outreach.businessType ? handleBusinessType($event) : ''"
              v-if="outreach.businessTypes.length > 0" />
            <div class="input-container has-label">
              <label>Tag(s)</label>
              <multiselect
                v-model="outreach.tags"
                :options="tagList"
                :searchable="true"
                :allow-empty="true"
                :close-on-select="true"
                :multiple="true"
                placeholder=''>
              </multiselect>
            </div>
          </div>
          <div class="flex-clear">
            <Textarea
              :hasLabel="true"
              labelText="Notes"
              placeholder="Notes"
              v-model="outreach.notes" />
          </div>
          <div class="flex-clear">
            <Dropdown
              dropdownPlaceholder="Select prospect list"
              :hasIcon="true"
              :inputHasLabel="true"
              :isSearch="true"
              inputLabelText="Add to prospect list"
              :options="filteredProspectLists"
              customOption="name"
              ref="prospectListDropdown"
              v-if="outreachProspectLists.length > 0"
              @input="outreachProspectList = $event"
              :createOption="true"
              createOptionMessage="Create New Prospect List"
              @createOptionClick="toggleOutreachProspectListMode($event)"
              @select="handleProspectList($event)" />
          </div>
        </div>
      </Form>
      <Form
        ref="prospectListForm"
        v-else
        @submit.prevent="addToProspectList">
        <div class="flex" style="justify-content: center;">
          <legend
            v-if="filteredWebsiteObj.owner"
            style="display: block;">
            Prospect Owner: {{ filteredWebsiteObj.owner_name }}
          </legend>
          <Dropdown
            dropdownPlaceholder="Select prospect list"
            :hasIcon="true"
            :inputHasLabel="true"
            :isSearch="true"
            inputLabelText="Add to prospect list"
            :options="filteredProspectLists"
            customOption="name"
            ref="prospectListDropdown"
            v-if="outreachProspectLists.length > 0"
            @input="outreachProspectList = $event"
            @select="outreachProspectList = $event" />
        </div>
      </Form>
      <alert ref="alert"></alert>
    </template>
    <template slot="footer">
      <Button
        btnType="fancy"
        text="add prospect"
        :isUppercase="true"
        @click="submitForm('outreachForm')"
        v-if="mode === 'create'" />
      <Button
        btnType="fancy"
        text="Update Prospect"
        :isUppercase="true"
        @click="submitForm('prospectListForm')"
        v-else />
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/modal/Modal'
import Button from '@/components/form/Button'
import Form from '@/components/form/Form'
import Multiselect from 'vue-multiselect'
import Dropdown from '@/components/form/Dropdown'
import Input from '@/components/form/Input'
import Textarea from '@/components/form/Textarea'
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'OutreachModal',
  components: {
    Modal,
    Button,
    Form,
    Multiselect,
    Dropdown,
    Input,
    Textarea
  },
  data () {
    return {
      tagList: [],
      outreach: {
        owners: [],
        businessTypes: [],
        name: '',
        firstName: '',
        lastName: '',
        website: '',
        owner: '',
        ownerId: '',
        businessType: '',
        businessTypeId: '',
        address: {
          city: '',
          state: ''
        },
        tags: [],
        notes: ''
      },
      marketingCompany: '',
      outreachCompanies: [],
      outreachProspectLists: [],
      outreachProspectList: '',
      outreachProspectListObj: {},
      outreachProspectDropdownCreateMode: false,
      newProspectList: ''
    }
  },
  props: {
    selectedWebsite: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: true
    },
    location: {
      type: String,
      default: ''
    },
    practiceArea: {
      type: String,
      default: ''
    },
    arcDetails: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: String,
      default: 'create'
    }
  },
  watch: {
    selectedWebsite: {
      handler: function (val) {
        this.outreach.website = val
      },
      immediate: true
    },
    arcDetails: {
      handler: function (val) {
        if (val !== undefined && val.practiceArea !== undefined && val.location !== '') {
          const location = val.location.split(', ')

          if (location[0] !== 'undefined') {
            this.outreach.address.city = location[0]
            this.outreach.address.state = location[1]
          }
        }
        const businessType = this.outreach.businessTypes.filter(type => {
          return type.name.toLowerCase() === val.practiceArea
        })[0]

        if (businessType) {
          this.outreach.businessType = businessType.name
          this.outreach.businessTypeId = businessType.id
        }

        // this.getOutreachCompanies(null, this.outreach.address.city, this.outreach.address.state)
        // const location = val.split(', ')
        // if (location[0] !== 'undefined') {
        //   this.outreach.address.city = location[0]
        //   this.outreach.address.state = location[1]

        // }
      },
      immediate: true
    },
    'outreach.businessTypeId': {
      handler: function (val) {
        this.getOutreachCompanies(null, this.outreach.address.city, this.outreach.address.state, val, null)
        this.$refs.businessTypeDropdown.select(this.outreach.businessType)
      }
      // immediate: true
    }
  },
  computed: {
    ...mapGetters({
      profile: 'auth/getProfile'
    }),

    ...mapState({
      formData: state => state.form
    }),

    filteredCities () {
      return this.searchCityList(this.outreach.address.state, this.outreach.address.city, this.formData.usLocations).slice(0, 100)
    },

    filteredStates () {
      return this.searchStateList(this.outreach.address.state, this.formData.usStates).slice(0, 20)
    },

    filteredWebsiteObj () {
      // match this.selectedWebsite to a company in this.outreachCompanies, it has to be filtered by object.websites
      // if my selectedWebsite is https://tgalaw.com i want to match if the object.websites contain that even if its in this format https://www.tgalaw.com
      return this.outreachCompanies.filter(company => {
        return company.websites.filter(website => {
          return website.includes(this.selectedWebsite)
        }).length > 0
      })[0]
    },

    filteredProspectListObj () {
      return this.filteredProspectLists.filter(list => {
        return list.name.toLowerCase() === this.outreachProspectList.toLowerCase()
      })[0]
    },

    filteredProspectLists () {
      return this.outreachProspectList === ''
        ? this.outreachProspectLists
        : this.outreachProspectLists.filter(list => {
          return list.name.toLowerCase().includes(this.outreachProspectList.toLowerCase())
        })
    }
  },
  created () {
    this.getAllData()
  },
  mounted () {
  },
  methods: {
    getAllData () {
      this.getOutreachBusinessTypes()
      this.getOutreachOwners()
      this.getOutreachTags()
      this.getOutreachProspectLists()
    },

    submitForm (formRef) {
      const form = this.$refs[formRef].$el

      if (form) {
        form.dispatchEvent(new Event('submit'))
      } else {
        console.error(`Form with ref ${formRef} not found.`)
      }
    },

    toggleOutreachProspectListMode (evt) {
      this.outreachProspectDropdownCreateMode = !this.outreachProspectDropdownCreateMode
    },

    closeModal (evt) {
      this.$emit('modal:closed', evt)
      this.clearProspectModal()
    },

    clearProspectModal () {
      this.outreach.firstName = ''
      this.outreach.lastName = ''
      this.outreach.name = ''
      this.outreach.address = { city: '', state: '' }
      this.outreach.tags = []
      this.outreach.notes = ''
      // this.searchOutreachCompanies(this.outreach.name)
    },

    searchOutreachCompanies: _.debounce(function (evt) {
      this.outreach.name = evt
      this.getOutreachCompanies(evt)
    }, 700),

    getOutreachOwners () {
      this.$outreach.get('users/owners/')
        .then(res => {
          this.outreach.owners = res.data.results

          if (this.profile.name) {
            const split = this.profile.name.split(' ')
            const ownerObj = this.outreach.owners.filter(owner => {
              return owner.first_name === split[0] && owner.last_name === split[1]
            })[0]
            if (ownerObj) {
              this.outreach.ownerId = ownerObj.id
              this.outreach.owner = ownerObj.first_name + ' ' + ownerObj.last_name
            }
          }
        })
        .catch(err => {
          console.error(err)
        })
    },

    getOutreachBusinessTypes () {
      this.$outreach.get('business_types/')
        .then(res => {
          this.outreach.businessTypes = res.data.results
        })
        .catch(err => {
          console.error(err)
        })
    },

    getOutreachCompanies (name, city, state, businessType, status) {
      this.$outreach.get('companies/', {
        params: {
          ...((name !== null && name !== '') && String(name).length > 2 ? { name_like: name } : {}),
          ...(city !== '' ? { city: city } : {}),
          ...(state !== '' ? { state: state } : {}),
          ...(status !== '' ? { status: status } : {}),
          ...(businessType !== '' ? { business_type: businessType } : {})
        }
      }).then(res => {
        this.outreachCompanies = res.data.results
        this.$emit('prospect:listed', res.data.results)
      }).catch(err => {
        console.error(err)
      })
    },

    getOutreachTags () {
      this.$outreach.get('tags/')
        .then(res => {
          res.data.results.forEach(el => {
            if ('name' in el) {
              this.tagList.push(el.name)
            }
          })
        })
        .catch(err => {
          console.error(err)
        })
    },

    getOutreachProspectLists () {
      this.$outreach.get('prospect_lists/')
        .then(res => {
          this.outreachProspectLists = res.data.results
        }).catch(err => {
          console.error(err)
        })
    },

    handleProspectList (evt) {
      const prospectListObj = this.outreachProspectLists.filter(list => {
        return list.name.toLowerCase() === evt.toLowerCase()
      })[0]

      this.outreachProspectListObj = prospectListObj
    },

    handleOutreachOwner (evt) {
      this.outreach.owner = evt
      const split = this.outreach.owner.split(' ')
      const ownerObj = this.outreach.owners.filter(owner => {
        return owner.first_name === split[0] && owner.last_name === split[1]
      })[0]
      if (ownerObj) {
        this.outreach.ownerId = ownerObj.id
      }
    },

    handleBusinessType (evt) {
      this.outreach.businessType = evt
      const businessTypeObj = this.outreach.businessTypes.filter(type => {
        return type.name.toLowerCase() === this.outreach.businessType.toLowerCase()
      })[0]
      if (businessTypeObj) {
        this.outreach.businessTypeId = businessTypeObj.id
      }
    },

    validateCreateOutreachForm () {
      const outreachForm = this.$refs.outreachForm.$el
      const outreachFormInputs = outreachForm.querySelectorAll('input, textarea, select')

      outreachFormInputs.forEach(input => {
        if (input.required && input.value === '') {
          input.parentNode.classList.add('error')
        } else {
          input.parentNode.classList.remove('error')
        }
      })

      return outreachForm.querySelectorAll('.error').length === 0
    },

    async createOutreachProspect (name, firstName, lastName, owner, website, businessType, address, tags, notes) {
      if (!this.validateCreateOutreachForm()) {
        return
      }
      if (notes === '') {
        notes = `Prospect added by ${this.profile.name} on ${new Date().toLocaleString()}`
      }
      if (address.state === '' && address.city === '') {
        address = {}
      }
      const outreachData = {
        name: name,
        ...(firstName !== '' && firstName !== '') && {
          contact: {
            ...(firstName !== '' && { first_name: firstName }),
            ...(lastName !== '' && { last_name: lastName })
          }
        },
        owner: owner,
        websites: [`https://${website}`],
        business_type: businessType,
        status: 'prospect',
        address: address,
        tags: tags,
        notes: notes
      }

      try {
        const outreachPostData = await this.$outreach.post('companies/', JSON.stringify(outreachData))

        if (outreachPostData.status === 201) {
          if (this.outreachProspectListObj !== {} && this.outreachProspectListObj.id !== undefined) {
            const prospectListData = {
              prospect_list: this.outreachProspectListObj.id,
              company: outreachPostData.data.id
            }
            const prospectListPostData = await this.$outreach.post(
              `prospect_lists/${prospectListData.prospect_list}/companies/`,
              JSON.stringify({
                id: [prospectListData.company]
              })
            )
            if (prospectListPostData.status === 201) {
              this.closeModal()
              this.$emit('prospect:created', outreachPostData.data.websites)
              this.$refs.alert.showAlert(`New prospect created and added to ${this.outreachProspectListObj.name}!`, 'success')
            } else {
              this.$refs.alert.showAlert('Error creating prospect', 'error')
            }
          } else {
            this.closeModal()
            this.$emit('prospect:created', outreachPostData.data.websites)
            this.$refs.alert.showAlert('New prospect created!', 'success')
          }
        } else {
          this.$refs.alert.showAlert('Error creating prospect', 'error')
        }
      } catch (error) {
        console.error('Error creating prospect')
        console.error(error.response?.data)
        if (error.response.data.non_field_errors) {
          error.response.data.non_field_errors.forEach(err => {
            this.$refs.alert.showAlert(err, 'error')
          })
        }
      }
    },
    async addToProspectList () {
      if (this.filteredWebsiteObj.id && this.filteredProspectListObj.id) {
        const prospectListPostData = await this.$outreach.post(
          `prospect_lists/${this.filteredProspectListObj.id}/companies/`,
          JSON.stringify({
            id: [this.filteredWebsiteObj.id]
          })
        )

        if (prospectListPostData.status === 201) {
          this.closeModal()
          // this.$emit('prospect:created', this.filteredWebsiteObj.websites)
          this.$refs.alert.showAlert(`Prospect added to ${this.filteredProspectListObj.name}!`, 'success')
        } else {
          this.$refs.alert.showAlert('Error adding to prospect list', 'error')
        }
      }
    }
  }
}
</script>

<style lang="scss">
.outreach-modal {
  .modal {
    &-card {
      &-header {
        padding-bottom: 1rem;
        h2 {
          font-family: "DM Serif Display", serif;
          color: #01307E;
          font-size: 2.5rem;
          &:after {
            content: '';
            display: block;
            width: 183px;
            height: 1px;
            background: #EE6152;
            margin: 24px auto;
          }
        }
      }
    }
  }
}
</style>
