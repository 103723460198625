<template>
  <BaseLayout
    title="Domain Details"
    :customClasses="['domain-profile']"
    ref="baseLayout">
    <Loading
      v-show="loading"
      text="Loading overview" />
    <div
      class="data-overview"
      v-if="Object.entries(domainOverview).length > 0 && !loading">
      <h2>
        {{ domainOverview.name }}
        <router-link :to="{
          name: 'site-history',
          query: {
            domain: domainOverview.name
          }
        }"
        target="_blank"
        rel="noopener">
          <font-awesome-icon icon="history"></font-awesome-icon>
        </router-link>
      </h2>
      <h3>Practice Area & Location Targets</h3>
      <Table>
        <template slot="head">
          <t-row>
            <t-heading>Target Location</t-heading>
            <t-heading
              :hasTip="true"
              thTip="(Double click to edit)">
              Target Practice Area
            </t-heading>
            <t-heading
              :hasTip="true"
              thTip="(Double click to edit)">
              Campaign Focus
            </t-heading>
            <t-heading>Date Added</t-heading>
          </t-row>
        </template>
        <template slot="body">
          <t-row
            v-for="(target, i) in domainTargetRows"
            :key="i">
            <t-cell>
              {{ target.dfs_location_name !== null ? formatLocation(target.dfs_location_name) : 'N/A' }}
            </t-cell>
            <t-cell
              :isEditable="true"
              :isDropdownSearch="false"
              :dropdownOptions="allPracticeAreas"
              @doneEditing="updatePracticeArea(i, $event)">
              {{ target.practice_area_name !== null ? capitalizeString(target.practice_area_name) : 'N/A' }}
            </t-cell>
            <t-cell
              :isEditable="true"
              :isDropdownSearch="false"
              :dropdownOptions="['Primary', 'Secondary']"
              @dropdownSelect="editedCampaignFocus = $event"
              @doneEditing="updateCampaignFocus(i)">
              {{ target.focus !== null ? parseInt(target.focus) === 1 ? 'Primary' : 'Secondary' : 'N/A' }}
            </t-cell>
            <t-cell>{{ target.created_at | moment('M/DD/YYYY') }}</t-cell>
            <t-cell>
              <Input
                type="checkbox"
                :hasLabel="true"
                :checked="false"
                @labelClick="deleteDomainTarget(target.id, i)" />
            </t-cell>
          </t-row>
          <t-row
            v-if="domainTargetRows.length === 0">
            <t-cell>No current targets</t-cell>
          </t-row>
        </template>
      </Table>
      <button-group
        btnAlign="right">
        <Button
          :hasIcon="true"
          icon="plus"
          type="button"
          text="Add New"
          :isInversed="true"
          :isBordered="true"
          @click="toggleTargetModal" />
      </button-group>
      <Modal
        :showModal="targetModalIsActive"
        @close="toggleTargetModal">
        <template slot="header">
          <h1>Add targets</h1>
        </template>
        <template slot="body">
          <div class="flex">
            <Dropdown
              :options="filteredLocations"
              :isSearch="true"
              dropdownPlaceholder="Start typing location"
              :inputHasLabel="true"
              inputId="city"
              inputLabelText="Select city"
              @select="targetForm.location = $event"
              @input="debounceLocationName($event)"
              :inputVal="targetForm.location"
              :isDisabled="loading"
              ref="targetLocation" />
            <Dropdown
              :options="allPracticeAreas"
              :hasIcon="true"
              dropdownPlaceholder="Practice Area"
              :inputHasLabel="true"
              inputId="practiceArea"
              inputLabelText="Select practice area"
              @select="targetForm.practice_area = $event"
              @input="targetForm.practice_area = $event"
              :inputVal="targetForm.practice_area"
              :isDisabled="loading"
              ref="targetPracticeArea" />
            <Dropdown
              :options="[1, 2]"
              :hasIcon="true"
              dropdownPlaceholder="Focus"
              :inputHasLabel="true"
              inputLabelText="Select campaign focus"
              @select="targetForm.focus = $event"
              @input="targetForm.focus = $event"
              :inputVal="targetForm.focus"
              :isDisabled="loading"
              ref="targetFocus" />
          </div>
        </template>
        <template slot="footer">
          <Button
            type="button"
            text="Add"
            btnType="primary"
            @click="addDomainTarget(concattedTargets)" />
        </template>
      </Modal>
      <h3>Vendor History</h3>
      <Table>
        <template slot="head">
          <t-row>
            <t-heading>Vendor Name</t-heading>
            <t-heading>Average ARC Score</t-heading>
            <t-heading
              :isEditable="true"
              :hasTip="true"
              thTip="(Double click to edit)">
              Start Date
            </t-heading>
            <t-heading
              :isEditable="true"
              :hasTip="true"
              thTip="(Double click to edit)">
              End Date
            </t-heading>
            <t-heading
              :isEditable="false"
              :hasTip="true"
              thTip="(Was manually overridden)">
              Manually Overriden
            </t-heading>
          </t-row>
        </template>
        <template slot="body">
          <t-row
            v-for="(history, i) in domainVendorRows"
            :key="i">
            <t-cell>{{ history.marketing_company }}</t-cell>
            <t-cell>{{ history.avg_arc_score }}</t-cell>
            <t-cell
              :isEditable="true"
              :isInput="true"
              placeholder="DD/MM/YYYY"
              @cancelEdit="newVendorStart = ''"
              @editInput="editVendorStart($event)"
              @doneEditing="updateVendorStart(history, newVendorStart)">
              {{ history.start_date !== null ? formatDate(history.start_date) : 'N/A' }}
            </t-cell>
            <t-cell
              :isEditable="true"
              :isInput="true"
              placeholder="DD/MM/YYYY"
              @cancelEdit="newVendorEnd = ''"
              @editInput="editVendorEnd($event)"
              @doneEditing="updateVendorEnd(history, newVendorEnd)">
              {{ history.end_date !== null ? formatDate(history.end_date) : 'present' }}
            </t-cell>
            <t-cell>
              <vue-toggle-btn :options="{isActive: domainOverview.manually_overriden}" @setIsActive="manuallyOverride($event)" v-if="i==0"></vue-toggle-btn>
            </t-cell>
            <t-cell>
              <Input
                type="checkbox"
                :hasLabel="true"
                :checked="false"
                @labelClick="deleteVendorEntry(history.id, i)" />
            </t-cell>
          </t-row>
          <t-row
            v-if="domainVendorRows.length === 0">
            <t-cell>No current vendors</t-cell>
          </t-row>
        </template>
      </Table>
      <button-group
        btnAlign="right">
        <Button
          :hasIcon="true"
          icon="plus"
          type="button"
          text="Add New"
          :isInversed="true"
          :isBordered="true"
          @click="toggleVendorModal" />
      </button-group>
      <Modal
        :showModal="vendorModalActive"
        @close="toggleVendorModal">
        <template slot="header">
          <h1>Add vendor</h1>
        </template>
        <template slot="body">
          <div class="flex">
            <Dropdown
              :options="filteredMarketingVendors"
              customOption="name"
              :isSearch="true"
              emptyResultsMessage="Start typing an agency name"
              dropdownPlaceholder="Marketing Company"
              :inputHasLabel="true"
              inputId="marketingCompany"
              inputLabelText="Select marketing company"
              @select="vendorForm.marketingCompany = $event"
              @input="debounceAgencyName($event)"
              :inputVal="vendorForm.marketingCompany"
              :isDisabled="loading" />
          </div>
        </template>
        <template slot="footer">
          <Button
            type="button"
            text="Add"
            btnType="primary"
            @click="addDomainVendor(
              $route.query.domain,
              vendorForm.marketingCompany
            )" />
        </template>
      </Modal>
      <h3>Domain Settings</h3>
      <button-group
        btnAlign="left">
        <div class="domain-settings">
        <div>
          <vue-toggle-btn :options="{isActive: domainOverview.excl_from_arc_score_calc}" @setIsActive="excludeDomainArc($event)"></vue-toggle-btn>
        <label>Exclude from all ARC calculations</label>
        </div>
        </div>
      </button-group>
    </div>
    <p v-if="!loading && Object.entries(domainOverview).length === 0">
      No data for {{ $route.query.domain }}. Please check spelling or try a different domain.
    </p>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/views/BaseLayout'
import Table from '@/components/table/Table'
import TableDataCell from '@/components/table/TableDataCell'
import TableHeading from '@/components/table/TableHeading'
import TableRow from '@/components/table/TableRow'
import Input from '@/components/form/Input'
import Button from '@/components/form/Button'
import ButtonGroup from '@/components/form/ButtonGroup'
import Loading from '@/components/misc/Loading'
import Modal from '@/components/modal/Modal'
import Dropdown from '@/components/form/Dropdown'
import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'
import VueToggleBtn from '@/components/form/Toggle'

export default {
  name: 'DomainProfile',
  components: {
    BaseLayout,
    Table,
    Input,
    Loading,
    Modal,
    Button,
    Dropdown,
    ButtonGroup,
    VueToggleBtn,
    't-row': TableRow,
    't-cell': TableDataCell,
    't-heading': TableHeading
  },
  data () {
    return {
      loading: false,
      domainOverview: {},
      domainTargets: [],
      domainVendorHistory: [],
      targetForm: {
        location: '',
        practice_area: '',
        focus: ''
      },
      vendorForm: {
        marketingCompany: ''
      },
      // marketingCompanies: [],
      targetModalIsActive: false,
      vendorModalActive: false,
      editedCampaignFocus: '',
      newVendorStart: '',
      newVendorEnd: '',
      filteredLocations: [],
      filteredMarketingVendors: []
    }
  },
  mounted () {
    this.getDomainOverview(this.$route.query.domain)
    // this.getMarketingVendors()
  },
  computed: {
    ...mapState({
      formData: state => state.form
    }),

    formattedDomainTargetList () {
      return this.domainTargetRows.map(target => {
        return {
          practice_area_name: target.practice_area_name,
          dfs_location_name: target.dfs_location_name,
          focus: target.focus
        }
      })
    },

    concattedTargets () {
      return this.formattedDomainTargetList.concat([{
        practice_area_name: this.targetForm.practice_area !== '' ? this.targetForm.practice_area : null,
        dfs_location_name: this.targetForm.location !== '' ? this.targetForm.location.replace(/, /g, ',') : null,
        focus: this.targetForm.focus
      }])
    },

    ...mapGetters({
      allUsLocations: 'form/allUsLocations',
      allPracticeAreas: 'form/allPracticeAreas'
    }),

    domainTargetRows () {
      return this.domainTargets
    },

    domainVendorRows () {
      return this.domainVendorHistory
    }

    // filteredMarketingVendors () {
    //   return this.searchMarketingCompanies(
    //     this.vendorForm.marketingCompany,
    //     this.formData.marketingCompanies
    //   ).slice(0, 100)
    // }

    // filteredLocations () {
    //   return this.searchLocationList(
    //     this.targetForm.location,
    //     this.allUsLocations
    //   ).slice(0, 100)
    // }
  },
  methods: {
    log (e) {
      console.log(e)
    },

    debounceLocationName: _.debounce(function (val) {
      if (val.length > 2) {
        const cleanedVal = val.replace(/, /g, ',')
        this.getLocations(cleanedVal)
      } else {
        this.filteredLocations = []
        this.targetForm.location = ''
      }
      // this.$forceUpdate()
    }, 200),

    debounceAgencyName: _.debounce(function (val) {
      if (val.length > 2) {
        this.vendorForm.marketingCompany = val
        this.getMarketingVendors(val)
      } else {
        this.filteredMarketingVendors = []
      }
      // this.$forceUpdate()
    }, 200),

    getMarketingVendors (name) {
      this.$arc.get('vendors?per_page=1000', {
        params: {
          ...(name ? { name_like: name } : {})
        }
      })
        .then(res => {
          this.filteredMarketingVendors = res.data.marketing_companies
          // this.getPagedResults()
        })
        .catch(err => {
          console.error(err)
        })
    },

    getLocations (name) {
      this.filteredLocations = this.searchUsLocationList(name).map(location => {
        return location.replace(/,/g, ', ')
      })
    },

    formatDate (date) {
      return this.$moment(date).format('MM/DD/YYYY')
    },
    manuallyOverride (e) {
      // Get dates branch separate
      // this.domainOverview.marketing_company = this.domainVendorHistory[0].marketing_company
      const date = new Date(this.domainVendorHistory[0].start_date)
      let endDate = this.domainVendorHistory[0].end_date ? new Date(this.domainVendorHistory[0].end_date) : null
      if (endDate != null) {
        endDate = endDate.toISOString()
      }
      this.updateDomain({
        name: this.domainOverview.name,
        validity_period_lower: date.toISOString(),
        validity_period_upper: endDate,
        manual_override: Number(e),
        marketing_company: this.domainVendorHistory[0].marketing_company
      })
      // this.updateDomain({ manually_override: true })
    },
    updateDomain (domain) {
      this.$arc.put('domains', {
        ...domain
      }).then(res => {
        this.domainOverview = res.data
        this.getDomainVendorHistory(this.domainOverview.name)
      }).catch(err => {
        console.error(err)
      })
    },
    excludeDomainArc (exclude) {
      const domain = this.domainOverview.name
      this.$arc.patch(`domains/${domain}`, {
        excl_from_arc_score_calc: Number(exclude)
      }).then(res => {
        this.domainOverview = res.data
      }).catch(err => {
        console.error(err)
      })
    },
    getDomainOverview (domain) {
      this.loading = true
      this.$arc.get(`domains/${domain}`)
        .then(res => {
          this.domainOverview = res.data
          this.loading = false
          this.getDomainVendorHistory(this.domainOverview.name)
          this.getDomainTargets(this.domainOverview.name)
        }).catch(err => {
          console.error(err)
          this.loading = false
        })
    },
    getDomainTargets (domain) {
      this.loading = true
      this.$arc.get(`domains/${domain}/targets`)
        .then(res => {
          this.domainTargets = res.data.domain_targets
          this.loading = false
        }).catch(err => {
          console.error(err)
          this.loading = false
        })
    },
    getDomainVendorHistory (domain) {
      this.loading = true
      this.$arc.get(`domains/${domain}/vendor_history`)
        .then(res => {
          this.domainVendorHistory = res.data.vendor_history
          this.loading = false
        }).catch(err => {
          console.error(err)
          this.loading = false
        })
    },

    updatePracticeArea (i, evt) {
      this.domainTargets[i].practice_area_name = evt
      this.$nextTick(() => {
        if (
          this.domainTargets[i].practice_area_name !== undefined ||
          this.domainTargets[i].practice_area_name !== ''
        ) {
          this.addDomainTarget(this.formattedDomainTargetList)
        }
      })
    },

    updateCampaignFocus (i) {
      const focusKey = this.editedCampaignFocus === 'Primary' ? 1 : 2
      this.domainTargets[i].focus = parseInt(focusKey)
      this.$nextTick(() => {
        if (
          this.domainTargets[i].focus !== undefined ||
          this.domainTargets[i].focus !== ''
        ) {
          this.addDomainTarget(this.formattedDomainTargetList)
          this.editedCampaignFocus = ''
        }
      })
    },

    addDomainTarget (targets) {
      this.$arc.put(`domains/${this.$route.query.domain}/targets`, {
        targets: targets
      }).then(res => {
        if (this.targetModalIsActive) {
          this.toggleTargetModal()
        }
        this.targetForm.location = ''
        this.targetForm.practice_area = ''
        this.targetForm.focus = ''
        this.$arc.get(`domains/${this.$route.query.domain}/targets`)
          .then(res => {
            this.domainTargets = res.data.domain_targets
          })
      }).catch(err => {
        console.error(err)
      })
    },

    deleteDomainTarget (targetId, key) {
      this.$arc.put(`domains/${this.$route.query.domain}/targets`, {
        targets: this.domainTargets.filter(target => target.id !== targetId)
      }).then(res => {
        this.domainTargets.splice(key, 1)
        this.getDomainOverview(this.$route.query.domain)
      }).catch(err => {
        console.error(err)
      })
    },
    searchMarketingCompanies (company, list) {
      return (company.length === '')
        ? list.slice(0, 100)
        : list.filter(item => {
          return item.name
            .toLowerCase()
            .includes(company.toLowerCase())
        }).sort((a, b) => {
          if (a < b) { return -1 }
          if (a > b) { return 1 }
          return 0
        })
    },
    deleteVendorEntry (vendorId, key) {
      this.$arc.delete(`vendor_history/${vendorId}`)
        .then(res => {
          this.domainVendorHistory.splice(key, 1)
          this.getDomainOverview(this.$route.query.domain)
        }).catch(err => {
          console.error(err)
        })
    },
    addDomainVendor (domain, marketingCompany) {
      this.$arc.put('domains', {
        name: `${domain}`,
        marketing_company: `${marketingCompany}`
      }).then(res => {
        this.toggleVendorModal()
        this.getDomainOverview(this.$route.query.domain)
        this.vendorForm.marketingCompany = ''
        this.$arc.get(`domains/${res.data.name}/vendor_history`)
          .then(res => {
            this.domainVendorHistory = res.data.vendor_history
          })
      }).catch(err => {
        console.error(err)
      })
    },
    editVendorStart (evt) {
      this.newVendorStart = evt
      this.$forceUpdate()
    },
    editVendorEnd (evt) {
      this.newVendorEnd = evt
      this.$forceUpdate()
    },
    updateVendorStart (vendor, start) {
      const formattedEnd = vendor.end_date !== null ? this.$moment(vendor.end_date).format('YYYY-MM-DD') : null
      const formattedStart = start !== null ? this.$moment(start).format('YYYY-MM-DD') : null
      if (start !== '') {
        this.$arc.patch(`vendor_history/${vendor.id}`, {
          start_date: formattedStart,
          ...(formattedEnd !== null ? { end_date: formattedEnd } : {})
        }).then(res => {
          this.$set(vendor, 'start_date', res.data.start_date)
          this.newVendorStart = ''
        }).catch(err => {
          console.error(err)
        })
      }
    },
    updateVendorEnd (vendor, end) {
      // validity_period: "[2022-08-11T00:43:56+00:00,)"
      const formattedStart = vendor.start_data !== null ? this.$moment(vendor.start_date).format('YYYY-MM-DD') : null
      const formattedEnd = end !== null ? this.$moment(end).format('YYYY-MM-DD') : null
      this.markVal = false
      if (end === '') {
        end = null
        this.markVal = true
      }
      const that = this
      this.$arc.patch(`vendor_history/${vendor.id}`, {
        end_date: formattedEnd,
        ...(formattedStart !== null ? { start_date: formattedStart } : {})
      }).then(res => {
        this.$set(vendor, 'end_date', res.data.end_date)
        this.newVendorStart = ''
        if (that.markVal) {
          that.domainOverview.marketing_company = res.data.marketing_company
          that.updateDomain(that.domainOverview)
        }
      }).catch(err => {
        console.error(err)
      })
    },
    toggleTargetModal () {
      this.targetModalIsActive = !this.targetModalIsActive
      if (!this.targetModalIsActive) {
        this.targetForm.location = ''
        this.targetForm.practice_area = ''
        this.targetForm.focus = ''
      }
    },
    toggleVendorModal () {
      this.vendorModalActive = !this.vendorModalActive
      if (!this.vendorModalActive) {
        this.vendorForm.marketingCompany = ''
        this.filteredMarketingVendors = []
      }
    }
  }
}
</script>

<style lang="scss">
section {
  &.domain-profile {
    .container {
      h2 {
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 50px;
        a {
          color: inherit;
        }
        svg {
          font-size: 16px;
          display: inline-block;
          margin-bottom: 5px;
        }
      }
      p {
        text-align: left;
      }
      h3 {
        font-size: 18px;
        font-weight: 900;
        margin-bottom: 10px;
      }
      .page-header {
        margin-bottom: 10px;
      }
      table {
        margin-bottom: 20px;
      }
      button {
        margin-bottom: 70px;
      }
      td .dropdown-container.is-absolute {
        height: 50px;
      }
      td .input.is-absolute {
        top: 4px;
        height: 50px;
      }
    }
    .flex {
      display: flex;
      > div {
        flex-basis: 100%;
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
  .domain-settings {
    display: flex;
    flex-direction: column;
    & > div {
      display: flex;
      flex-direction: row;
      margin: 1rem;
      label {
        margin-left: 1rem;
      }
    }
  }
}
</style>
