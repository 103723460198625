<template>
 <BaseLayout
   title="Scheduled ARC Reports"
   :customClasses="['schedule-arc']"
   ref="baseLayout">
        <button-group
        btnAlign="right">
        <Button
          :hasIcon="true"
          icon="plus"
          type="button"
          text="Add New"
          :isInversed="true"
          :isBordered="true"
          @click="toggleScheduleModal" />
      </button-group>
        <Modal
        :showModal="scheduleModalIsActive"
        @close="toggleScheduleModal">
        <template slot="header">
          <h1>Add Arc Schedule</h1>
        </template>
        <template slot="body">
          <div class="flex">
            <Dropdown
              :options="filteredLocations"
              :isSearch="true"
              dropdownPlaceholder="Start typing location"
              :inputHasLabel="true"
              inputId="city"
              inputLabelText="Select city"
              @select="scheduleForm.location = $event"
              @input="debounceLocationName($event)"
              :inputVal="scheduleForm.location"
              :isDisabled="loading"
              ref="scheduleLocation" />
            <Dropdown
              :options="allPracticeAreas"
              :hasIcon="true"
              dropdownPlaceholder="Practice Area"
              :inputHasLabel="true"
              inputId="practiceArea"
              inputLabelText="Select practice area"
              @select="scheduleForm.practice_area = $event"
              @input="scheduleForm.practice_area = $event"
              :inputVal="scheduleForm.practice_area"
              :isDisabled="loading"
              ref="schedulePracticeArea" />
            <Dropdown
              :options="['monthly', 'quarterly']"
              :hasIcon="true"
              dropdownPlaceholder="Frequency"
              :inputHasLabel="true"
              inputLabelText="Frequency"
              @select="scheduleForm.frequency = $event"
              :inputVal="scheduleForm.frequency"
              :isDisabled="loading"
              ref="scheduleFrequency" />
          </div>
        </template>
        <template slot="footer">
            <Button
                type="button"
                text="Add"
                btnType="primary"
                @click="addArcSchedule()" />
        </template>
      </Modal>
    <Loading v-show="loading" text="Loading schedules" />
    <Table
      :loading="loading"
      v-if="sortedResults.length > 0">
      <template slot="head">
        <t-row>
          <t-heading
            @click="sortBy('dfs_location_name')"
            :class="sortedClass('dfs_location_name')">
            Location
          </t-heading>
          <t-heading
            @click="sortBy('practice_area_name')"
            :class="sortedClass('practice_area_name')">
            Practice Area
          </t-heading>
          <t-heading
            @click="sortBy('last_run_at')"
            :class="sortedClass('last_run_at')">
            Date Last Run
          </t-heading>
          <t-heading
            @click="sortBy('frequency')"
            :class="sortedClass('frequency')">
            Frequency
          </t-heading>
        </t-row>
      </template>
      <template slot="body">
        <t-row
          v-for="(result, i) in sortedResults"
          :key="i">
          <t-cell>
            {{formatLocation(result.dfs_location_name)}}
          </t-cell>
          <t-cell>
            {{ result.practice_area_name !== null ? capitalizeString(result.practice_area_name) : 'N/A' }}
          </t-cell>
          <t-cell>{{ result.last_run_at | moment('M/DD/YYYY h:mm') }}</t-cell>
          <t-cell>
            {{ result.frequency !== null ? capitalizeString(result.frequency) : 'N/A' }}
          </t-cell>
          <t-cell>
            <Input
                type="checkbox"
                :hasLabel="true"
                :checked="false"
                @labelClick="deleteScheduledTask(result.id, i)" />
          </t-cell>
        </t-row>
      </template>
    </Table>
    <p v-else-if="!loading && sortedResults.length === 0">
      No results. Try changing or clearing filters.
    </p>
    <Pagination
      v-if="!loading"
      :totalItems="schedules.total"
      :perPage="schedules.per_page"
      :currentPage="schedules.page"
      :nextNum="schedules.next_num"
      :prevNum="schedules.prev_num"
      @initPagination="$event(pagination)"
      @pageChanged="changePage($event)" />
  </BaseLayout>
</template>

<script>
import Table from '@/components/table/Table'
import TableRow from '@/components/table/TableRow'
import TableDataCell from '@/components/table/TableDataCell'
import TableHeading from '@/components/table/TableHeading'
import Input from '@/components/form/Input'
import Dropdown from '@/components/form/Dropdown'
import Button from '@/components/form/Button'
import ButtonGroup from '@/components/form/ButtonGroup'
import Modal from '@/components/modal/Modal'
import BaseLayout from '@/views/BaseLayout'
import Loading from '@/components/misc/Loading'
import Pagination from '@/components/misc/Pagination'
import { mapState, mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'ScoreSchedule',
  components: {
    Table,
    Button,
    ButtonGroup,
    Loading,
    Pagination,
    Dropdown,
    BaseLayout,
    Input,
    Modal,
    't-row': TableRow,
    't-cell': TableDataCell,
    't-heading': TableHeading
  },
  data () {
    return {
      loading: false,
      schedules: {},
      sort: {
        key: 'last_checked',
        isAsc: false
      },
      pagination: {
        currentPage: 1,
        dropdownOptions: [20, 50, 100, 250, 500, 1000],
        per_page: 100
      },
      scheduleModalIsActive: false,
      scheduleForm: {
        location: '',
        practice_area: '',
        frequency: ''
      },
      filteredLocations: []
    }
  },
  computed: {
    ...mapState({
      formData: state => state.form
    }),
    ...mapGetters({
      allUsLocations: 'form/allUsLocations',
      allPracticeAreas: 'form/allPracticeAreas'
    }),
    sortedResults () {
      const list = this.schedules.scheduled_reports ? this.schedules.scheduled_reports : []
      if (this.sort.key) {
        list.sort((a, b) => {
          a = _.get(a, this.sort.key)
          b = _.get(b, this.sort.key)
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        })
      }
      return list
    }
  },
  mounted () {
    this.getArcSchedule()
  },
  methods: {
    sortedClass (key) {
      return this.sort.key === key
        ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}`
        : ''
    },
    debounceLocationName: _.debounce(function (val) {
      if (val.length > 2) {
        const cleanedVal = val.replace(/, /g, ',')
        this.getLocations(cleanedVal)
      } else {
        this.filteredLocations = []
        this.scheduleForm.location = ''
      }
      // this.$forceUpdate()
    }, 200),
    getLocations (name) {
      this.filteredLocations = this.searchUsLocationList(name).map(location => {
        return location.replace(/,/g, ', ')
      })
    },
    debounceAgencyName: _.debounce(function (val) {
      if (val.length > 2) {
        this.form.agencyName = val
        this.getMarketingCompanies(val)
      } else {
        this.filteredCompanies = []
      }
      // this.$forceUpdate()
    }, 200),
    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false
      this.sort.key = key
    },
    getMarketingCompanies (name) {
      this.$arc.get('marketing_companies?per_page=1000', {
        params: {
          ...(name ? { name_like: name } : {})
        }
      })
        .then(res => {
          this.filteredCompanies = res.data.marketing_companies
          // this.getPagedResults()
        })
        .catch(err => {
          console.error(err)
        })
    },
    getArcSchedule (e) {
      if (e && e.type === 'submit') {
        this.pagination.currentPage = 1
      }
      this.loading = true
      this.loading = false
      this.$arc.get('scheduled_reports', {
        params: {
          ...(this.pagination.currentPage ? { page: this.pagination.currentPage } : {}),
          ...(this.pagination.per_page ? { per_page: this.pagination.per_page } : {})
        }
      })
        .then(res => {
          this.schedules = res.data
          // this.getPagedResults()
        })
        .catch(err => {
          console.error(err)
        })
    },
    deleteScheduledTask (taskId, key) {
      this.$arc.delete(`scheduled_reports/${taskId}`)
        .then(res => {
          this.schedules.scheduled_reports.splice(key, 1)
          this.getArcSchedule()
        }).catch(err => {
          console.error(err)
        })
    },
    getPagedResults () {
    //   const promises = []
    //   for (
    //     let i = 2;
    //     i < Math.ceil(this.changes.total / this.changes.per_page) + 1;
    //     i++
    //   ) {
    //     promises.push(
    //       this.$arc.get(`domains/status_changes?page=${i}&per_page=1000`)
    //     )
    //   }
    //   Promise.all(promises).then((values) => {
    //     values.map(p => {
    //       this.changes.status_changes.push(...p.data.status_changes)
    //     })
    //   })
    },
    addArcSchedule () {
      this.$arc.post('scheduled_reports', {
        dfs_location_name: this.scheduleForm.location !== '' ? this.scheduleForm.location.replace(/, /g, ',') : null,
        practice_area_name: this.scheduleForm.practice_area,
        frequency: this.scheduleForm.frequency
      })
        .then(response => {
          if (this.scheduleModalIsActive) {
            this.toggleScheduleModal()
          }
          this.getArcSchedule()
        })
        .catch(error => {
          console.error(error)
        })
    },
    changePage (e) {
      this.pagination.currentPage = e
      this.getArcSchedule()
    },
    formatSlug (completedAt, location, practiceArea, taskId) {
      const completed = this.$moment(completedAt).format('M-DD-YYYY')
      const newLocation = location.toLowerCase().replace(', ', '-').replace(' ', '-')
      const area = practiceArea.toLowerCase().replace(' ', '-')
      return `${newLocation}/${area}/${completed}?id=${taskId}`
    },
    toggleScheduleModal () {
      this.scheduleModalIsActive = !this.scheduleModalIsActive
      if (!this.scheduleModalIsActive) {
        this.scheduleForm.location = ''
        this.scheduleForm.practice_area = ''
        this.scheduleForm.frequency = ''
        this.filteredLocations = []
      }
    }
  }
}
</script>

<style lang="scss">
section {
  &.schedule-arc {
    .container {
      h2 {
        font-size: 24px;
        font-weight: 900;
        margin-bottom: 50px;
        a {
          color: inherit;
        }
        svg {
          font-size: 16px;
          display: inline-block;
          margin-bottom: 5px;
        }
      }
      p {
        text-align: left;
      }
      h3 {
        font-size: 18px;
        font-weight: 900;
        margin-bottom: 10px;
      }
      .page-header {
        margin-bottom: 10px;
      }
      table {
        margin-bottom: 20px;
      }
      button {
        margin-bottom: 70px;
      }
      td .dropdown-container.is-absolute {
        height: 50px;
      }
      td .input.is-absolute {
        top: 4px;
        height: 50px;
      }
    }
    .flex {
      display: flex;
      > div {
        flex-basis: 100%;
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
  .domain-settings {
    display: flex;
    flex-direction: column;
    & > div {
      display: flex;
      flex-direction: row;
      margin: 1rem;
      label {
        margin-left: 1rem;
      }
    }
  }
}
</style>
