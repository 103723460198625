<template>
 <BaseLayout
   title="Agency Changes"
   :customClasses="['agency-changes']"
   ref="baseLayout">
   <Form
      @submit.prevent="filterResults">
        <label>
         Per page
        </label>
        <Dropdown
          dropdownPlaceholder="Results per page"
          :hasIcon="true"
          :options="pagination.dropdownOptions"
          @select="pagination.per_page = $event"
          :inputVal="pagination.per_page"
          :isDisabled="loading" />
    </Form>
    <Loading v-show="loading" text="Loading changes" />
    <Table
      :loading="loading"
      v-if="sortedResults.length > 0">
      <template slot="head">
        <t-row>
          <t-heading
            @click="sortBy('website')"
            :class="sortedClass('website')">
            Website
          </t-heading>
          <t-heading
            :textCentered="true"
            v-if="hasOutreachKey">
            Add Prospect
          </t-heading>
          <t-heading
            @click="sortBy('new_marketing_company.name')"
            :class="sortedClass('new_marketing_company.name')">
            New Agency
          </t-heading>
          <t-heading
            @click="sortBy('new_avg_arc_score')"
            :class="sortedClass('new_avg_arc_score')">
            NA ARC
          </t-heading>
          <t-heading
            @click="sortBy('old_marketing_company.name')"
            :class="sortedClass('old_marketing_company.name')">
            Old Agency
          </t-heading>
          <t-heading
            @click="sortBy('old_avg_arc_score')"
            :class="sortedClass('old_avg_arc_score')">
            OA ARC
          </t-heading>
          <t-heading
            @click="sortBy('date')"
            :class="sortedClass('date')">
            Date Discovered
          </t-heading>
        </t-row>
      </template>
      <template slot="body">
        <t-row
          v-for="(result, i) in sortedResults"
          :key="i">
          <t-cell>
            <a
              :href="result.website"
              target="_blank"
              rel="noopener">
              {{ result.website }}
            </a>
          </t-cell>
          <t-cell
            v-if="hasOutreachKey">
            <Button
              :hasIcon="true"
              :hasIconOnly="true"
              icon="plus"
              :isRounded="true"
              :isSmall="true"
              btnType="purple"
              @click="toggleModal(i)"
              :isDisabled="isIncludedInOutreach(formatDomain(result.website))" />
          </t-cell>
          <t-cell>
            <div class="logo-container">
              <img height="24" width="24" :src="result.new_marketing_company.favicon_url || result.new_marketing_company.icon_url ||  require('../../assets/img/fallback_fav.png')"/>
              {{ result.new_marketing_company.name }}
            </div>
            </t-cell>
          <t-cell>{{ result.new_avg_arc_score }}</t-cell>
          <t-cell>
            <div class="logo-container" v-if="result.old_marketing_company !== null">
              <img height="24" width="24" :src="result.old_marketing_company.favicon_url || result.old_marketing_company.icon_url || require('../../assets/img/fallback_fav.png')"/>
              {{ result.old_marketing_company.name }}
            </div>
          </t-cell>
          <t-cell>
            {{ result.old_avg_arc_score !== null ? result.old_avg_arc_score : 'n/a' }}
          </t-cell>
          <t-cell>{{ result.date | moment('M/DD/YYYY') }}</t-cell>
          <t-cell>
            <Button
              :hasLink="true"
              :btnLink="{
                name: 'profile',
                query: {
                  domain: formatDomain(result.website)
                }
              }"
              :isView="true" />
          </t-cell>
        </t-row>
      </template>
    </Table>
    <p v-else-if="!loading && sortedResults.length === 0">
      No results. Try changing or clearing filters.
    </p>
    <OutreachModal
      v-if="hasOutreachKey"
      :selectedWebsite="selectedWebsite"
      :isActive="modalActive"
      @modal:closed="toggleModal"
      @prospect:listed="getWebsitesInOutreach(false, $event)"
      @prospect:created="getWebsitesInOutreach(true, $event)" />
    <Pagination
      v-if="!loading"
      :totalItems="changes.total"
      :perPage="pagination.per_page"
      :currentPage="pagination.page"
      :nextNum="changes.next_num"
      :prevNum="changes.prev_num"
      @pageChanged="changePage($event)" />
  </BaseLayout>
</template>

<script>
import Table from '@/components/table/Table'
import TableRow from '@/components/table/TableRow'
import TableDataCell from '@/components/table/TableDataCell'
import TableHeading from '@/components/table/TableHeading'
import Button from '@/components/form/Button'
import Dropdown from '@/components/form/Dropdown'
import Form from '@/components/form/Form'
import BaseLayout from '@/views/BaseLayout'
import Loading from '@/components/misc/Loading'
import Pagination from '@/components/misc/Pagination'
import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'
import OutreachModal from '@/components/modal/OutreachModal'

export default {
  name: 'ScoreAgencyChanges',
  components: {
    Table,
    Button,
    Loading,
    Pagination,
    BaseLayout,
    Form,
    Dropdown,
    't-row': TableRow,
    't-cell': TableDataCell,
    't-heading': TableHeading,
    OutreachModal
  },
  data () {
    return {
      loading: true,
      changes: [],
      sort: {
        key: 'last_checked',
        isAsc: false
      },
      pagination: {
        page: 1,
        per_page: 100,
        dropdownOptions: [20, 50, 100, 250, 500, 1000]
      },
      websitesInOutreach: [],
      modalActive: false,
      selectedWebsite: ''
    }
  },
  computed: {
    ...mapGetters({
      hasOutreachKey: 'auth/hasOutreachKey'
    }),

    ...mapState({
      formData: state => state.form
    }),

    sortedResults () {
      const list = this.changes.changes ? this.changes.changes : []
      if (this.sort.key) {
        list.sort((a, b) => {
          a = _.get(a, this.sort.key)
          b = _.get(b, this.sort.key)
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        })
      }
      return list
    },

    allDomains () {
      const domains = []
      if (this.sortedResults.length > 0) {
        this.sortedResults.forEach(res => {
          domains.push(res.website.replace(/https:\/\/www.|http:\/\/www.|https:\/\/|http:\/\/|/, ''))
        })
      }
      return domains
    }
  },
  mounted () {
    this.getChanges()
  },
  methods: {
    sortedClass (key) {
      return this.sort.key === key
        ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}`
        : ''
    },

    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false
      this.sort.key = key
    },

    isIncludedInOutreach (domain) {
      return this.websitesInOutreach.includes(domain)
    },

    toggleModal (idx) {
      this.modalActive = !this.modalActive
      if (this.sortedResults[idx] !== undefined) {
        this.selectedWebsite = this.sortedResults[idx].website
      }
    },

    getWebsitesInOutreach (created, outreachCompanies) {
      const webResults = []

      if (created) {
        webResults.push(outreachCompanies)
      } else {
        outreachCompanies.forEach(item => {
          webResults.push(item.websites)
        })
      }
      this.websitesInOutreach = webResults.flat().map(item => {
        const regexItem = item.replace(/https:\/\/www.|http:\/\/www.|https:\/\/|http:\/\/|/, '')
        const newStr = regexItem.replace('/', '')
        return newStr
      })
    },

    async getChanges (page) {
      const initialPage = this.$route.query.page ? this.$route.query.page : 1
      const { data } = await this.$arc.get('marketing_companies/changes', {
        params: {
          per_page: 300,
          ...(page ? { page } : { page: initialPage })
        }
      })

      if (data) {
        this.changes = data
        this.pagination.page = this.changes.page
        this.loading = false
      } else {
        this.loading = false
      }
    },

    getPagedResults () {
      const promises = []
      for (
        let i = 2;
        i < Math.ceil(this.changes.total / this.changes.per_page) + 1;
        i++
      ) {
        promises.push(
          this.$arc.get(`marketing_companies/changes?page=${i}&per_page=300`)
        )
      }
      Promise.all(promises).then((values) => {
        values.map(p => {
          this.changes.changes.push(...p.data.changes)
        })
      })
      this.loading = false
    },

    changePage (e) {
      // console.log('page changed', e)
      this.loading = true
      this.getChanges(e)
    },

    formatDomain (domain) {
      return domain.toString().includes('https://www.') || domain.toString().includes('http://www.')
        ? domain.toString().replace('https://www.', '').replace('http://www.', '')
        : domain.toString().replace('https://', '').replace('http://', '')
    },

    formatSlug (completedAt, location, practiceArea, taskId) {
      const completed = this.$moment(completedAt).format('M-DD-YYYY')
      const newLocation = location.toLowerCase().replace(', ', '-').replace(' ', '-')
      const area = practiceArea.toLowerCase().replace(' ', '-')
      return `${newLocation}/${area}/${completed}?id=${taskId}`
    }
  }
}
</script>

<style lang="scss">
.agency-changes {
  form {
    align-items: center;
    margin-bottom: 30px;
    justify-content: flex-end;
    strong {
      margin-bottom: 1rem;
    }
    label {
      margin-right: 1rem;
    }
    > div {
      flex-basis: calc((100% / 4) - 150px) !important;
      &.flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        label {
          font-weight: 600;
          margin-bottom: 10px;
          display: block;
          width: 100%;
        }
        .input-container {
          flex-basis: calc((100% / 2) - 127px);
        }
      }
    }
    strong {
      margin-right: 15px;
    }
  }
  .logo-container {
    img {
      margin: 0 1rem;
      vertical-align: bottom;
      display: inline-block;
    }
  }
}
</style>
