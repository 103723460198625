<template>
  <BaseLayout
    title="Site Score History"
    :customClasses="['history']"
    ref="baseLayout">
    <template v-slot:page-header>
      <Form
        @submit.prevent="getSiteHistory(domainSearch)">
        <Input
          placeholder="Search history by domain"
          v-model="domainSearch"
          :disabled="loading" />
        <Button
          type="submit"
          text="Submit"
          :isDisabled="loading" />
      </Form>
    </template>
      <Chart
        width="100%"
        height="350px"
        :options="chartOptions"
        v-if="!loading && sortedResults.length > 0" />
      <div class="filters"
      >
      <strong>Filters</strong>
        <Dropdown
          :options="filteredLocations"
          :isSearch="true"
          dropdownPlaceholder="Location"
          :inputHasLabel="true"
          inputId="city"
          inputLabelText="Start typing location"
          ref="locationDropdown"
          @select="form.location = $event;"
          @input="debounceLocationName($event)"
          :inputVal="form.location"
          :isDisabled="loading" />
        <Dropdown
          :options="form.tiers"
          :hasIcon="true"
          :inputHasLabel="true"
          inputLabelText="Tier"
          ref="tierDropdown"
          dropdownPlaceholder="Tier"
          @input="form.tier = $event"
          @select="form.tier = $event"
          :isDisabled="loading" />
        <Dropdown
          :hasIcon="true"
          :options="formData.practiceAreas"
          dropdownPlaceholder="Practice Area"
          :inputHasLabel="true"
          inputId="practiceArea"
          inputLabelText="Practice Area"
          ref="practiceAreaDropdown"
          @select="form.practiceArea = $event"
          :isDisabled="loading" />
        <Button
          type="submit"
          @click="applyFilters"
          text="Apply filters"
          :isDisabled="loading" />
      </div>
      <action-links
        textAlign="right"
        v-if="!loading">
        <Button
          @click="clearFilters"
          text="Clear filters" />
      </action-links>
    <div class="flex" v-if="sortedResults.length > 0">
      <div class="domain-container">
        <h2>
          {{ tableData.domain }}
          <router-link :to="{
            name: 'profile',
            query: {
              domain: tableData.domain
            }
          }"
          target="_blank"
          rel="noopener">
            <font-awesome-icon icon="external-link-alt"></font-awesome-icon>
          </router-link>
        </h2>
        <button-group
          btnAlign="center"
          v-if="hasOutreachKey">
          <Button
            :hasIcon="true"
            :hasIconOnly="true"
            icon="plus"
            :isRounded="true"
            :isSmall="true"
            btnType="purple"
            @click="toggleModal(tableData.domain)"
            :isDisabled="isIncludedInOutreach(tableData.domain)" />
          <Button
            text="Add Prospect"
            :isPlain="true"
            @click="toggleModal(tableData.domain)"
            :isDisabled="isIncludedInOutreach(tableData.domain)" />
        </button-group>
      </div>
      <span v-if="avgArcScore !== 'NaN'" class="right-aligned">Avg Score: {{ avgArcScore }}</span>
      <span  class="right-aligned" v-else>Avg Score: <small>Location Excluded</small></span>
    </div>
    <Loading
      v-show="loading"
      text="Loading history" />
    <Table
      :loading="loading"
      v-if="!loading && sortedResults.length > 0">
      <template slot="head">
        <t-row>
          <t-heading
            @click="sortBy('number')"
            :class="sortedClass('number')">
            #
          </t-heading>
          <t-heading>Url</t-heading>
          <t-heading
            @click="sortBy('arc_score')"
            :class="sortedClass('arc_score')">
            Arc Score
          </t-heading>
          <t-heading
            @click="sortBy('practice_area_name')"
            :class="sortedClass('practice_area_name')">
            Practice Area
          </t-heading>
          <t-heading
            @click="sortBy('dfs_location_name')"
            :class="sortedClass('dfs_location_name')">
            City
          </t-heading>
          <t-heading
            @click="sortBy('market_tier')"
            :class="sortedClass('market_tier')">
            Tier
          </t-heading>
          <t-heading>Marketing Agency</t-heading>
          <t-heading
            @click="sortBy('last_checked_at')"
            :class="sortedClass('last_checked_at')">
            Last Checked
          </t-heading>
          <t-heading>View</t-heading>
          <t-heading>Include</t-heading>
        </t-row>
      </template>
      <template slot="body">
        <t-row
          v-for="(result, i) in sortedResults"
          :key="i">
          <t-cell>{{ result.number }}</t-cell>
          <t-cell>{{ tableData.domain || 'N/A' }}</t-cell>
          <t-cell>{{ result.arc_score || 'N/A' }}</t-cell>
          <t-cell>{{ capitalizeString(result.practice_area_name) || 'N/A' }}</t-cell>
          <t-cell>{{ formatLocation(result.location) || 'N/A' }}</t-cell>
          <t-cell>{{ result.market_tier || 'N/A' }}</t-cell>
          <t-cell>{{ result.marketing_company || 'N/A' }}</t-cell>
          <t-cell>{{ result.last_checked_at || 'N/A' | moment('M/DD/YYYY [at] hh:mma') }}</t-cell>
          <t-cell>
            <Button
              :hasLink="true"
              :btnLink="{
                path: `scores/${formatTaskSlug(
                  result.last_checked_at,
                  result.location,
                  result.practice_area_name,
                  result.task_id
                )}`
              }"
              :isView="true" />
          </t-cell>
          <t-cell :textCentered="true">
            <Input
              type="checkbox"
              :hasLabel="true"
              :inputId="`include-${result.result_id}`"
              :checked="isIncluded(result.result_id)"
              @click.prevent="includeOrExclude(domain, result.result_id)"
              :customLabelStyle="[
                result.included_in_avg
                  ? { backgroundColor: '#2cca6e' }
                  : !isIncluded(result.result_id)
                    ? {}
                    : { backgroundColor: 'red' }
              ]" />
          </t-cell>
        </t-row>
      </template>
    </Table>
    <OutreachModal
      :selectedWebsite="selectedWebsite"
      :isActive="modalActive"
      @modal:closed="toggleModal"
      @prospect:listed="getWebsitesInOutreach(false, $event)"
      @prospect:created="getWebsitesInOutreach(true, $event)" />
    <p
      v-if="
        !loading &&
        sortedResults.length === 0
      ">
      No results found, please try a different domain.
    </p>
  </BaseLayout>
</template>

<script>
import Table from '@/components/table/Table'
import TableRow from '@/components/table/TableRow'
import TableDataCell from '@/components/table/TableDataCell'
import TableHeading from '@/components/table/TableHeading'
import Input from '@/components/form/Input'
import Button from '@/components/form/Button'
import ButtonGroup from '@/components/form/ButtonGroup'
import Form from '@/components/form/Form'
import BaseLayout from '@/views/BaseLayout'
import Loading from '@/components/misc/Loading'
import Chart from '@/components/charts/Chart'
import Dropdown from '@/components/form/Dropdown'
import ActionLinks from '@/components/misc/ActionLinks'
import OutreachModal from '@/components/modal/OutreachModal'
import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'ScoreHistory',
  components: {
    Table,
    Input,
    Form,
    Chart,
    Button,
    ButtonGroup,
    Loading,
    BaseLayout,
    Dropdown,
    ActionLinks,
    OutreachModal,
    't-row': TableRow,
    't-cell': TableDataCell,
    't-heading': TableHeading
  },
  data () {
    return {
      loading: false,
      siteHistory: {},
      domainSearch: '',
      sort: {
        key: 'last_checked_at',
        isAsc: false
      },
      filters: {
        locationFilter: '',
        practiceAreaFilter: '',
        tierFilter: ''
      },
      form: {
        location: '',
        practiceArea: '',
        tier: '',
        tiers: [1, 2, 3, 4, 5]
      },
      results: [],
      filteredLocations: [],
      websitesInOutreach: [],
      modalActive: false,
      selectedWebsite: '',
      chartOptions: {
        chart: {
          type: 'scatter',
          zoomType: 'xy',
          spacingBottom: 60,
          backgroundColor: '#fbfdff'
        },
        credits: {
          enabled: false
        },
        title: {
          text: 'Arc Scores in each City by Practice Area'
        },
        subtitle: {
          text: 'Source: https://arc.1point21interactive.com'
        },
        xAxis: {
          title: {
            enabled: true,
            text: 'City'
          },
          categories: [],
          scrollbar: {
            enabled: true
          },
          labels: {
            enabled: false
          }
          // startOnTick: true,
          // endOnTick: true,
          // showLastLabel: true
        },
        yAxis: {
          title: {
            text: 'ARC Score'
          },
          min: 0,
          max: 11
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          y: 30,
          floating: true
        },
        plotOptions: {
          series: {
            opacity: 0.75,
            marker: {
              symbol: 'circle'
            },
            turboThreshold: 7000
          },
          scatter: {
            jitter: {
              y: 0.75,
              x: 1
            },
            marker: {
              radius: 4,
              states: {
                hover: {
                  enabled: true,
                  lineWidth: 0
                }
              }
            },
            states: {
              hover: {
                marker: {
                  enabled: false
                }
              }
            },
            tooltip: {
              headerFormat: '',
              pointFormatter: function () {
                const categoryName = this.series.chart.xAxis[0].categories[this.x]
                return `
                  <b>City: ${categoryName.replaceAll(',', ', ')}</b><br>
                  Practice Area: ${this.series.name}<br>
                  ARC Score: ${this.y}`
              }
            }
          }
        },
        series: []
      }
    }
  },
  computed: {
    ...mapGetters({
      tableData: 'table/getTableData',
      hasOutreachKey: 'auth/hasOutreachKey'
    }),

    ...mapState({
      formData: state => state.form
    }),

    sortedResults () {
      let list = []
      if (this.tableData.results) {
        list = this.tableData.results
      }
      if (this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        })
      }
      if (this.filters.practiceAreaFilter) {
        list = list.filter(result => result.practice_area_name.toLowerCase() === this.filters.practiceAreaFilter.toLowerCase())
      }
      if (this.filters.tierFilter) {
        list = list.filter(result => result.market_tier === this.filters.tierFilter)
      }
      if (this.filters.locationFilter) {
        list = list.filter(result => {
          const locFilter = this.filters.locationFilter.toLowerCase().replace(/, /g, ',')
          return result.location &&
          (result.location.toLowerCase().includes(
            locFilter) || this.formatLocation(result.location).toLowerCase().includes(this.filters.locationFilter.toLowerCase()))
        })
      }
      return list
    },

    avgArcScore () {
      return this.calculateAvgScore()
    },

    excludedList () {
      return this.checkDuplicates()
    }

  },
  watch: {
    sortedResults: {
      handler: function () {
        const { seriesData, categories } = this.getChartData()

        this.chartOptions.series = seriesData
        this.chartOptions.xAxis.categories = categories
      }
    }
  },
  mounted () {
    if (this.$route.query.domain) {
      this.getSiteHistory(this.$route.query.domain)
    } else {
      this.$store.dispatch('table/setTableData', {})
    }
  },
  methods: {
    log (e) {
      console.log(e)
    },

    toggleModal (domain) {
      this.modalActive = !this.modalActive
      this.selectedWebsite = 'https://' + domain
    },

    isIncludedInOutreach (domain) {
      return this.websitesInOutreach.includes(domain)
    },

    getRandomColor (seedString) {
      let hash = 0
      for (let i = 0; i < seedString.length; i++) {
        hash = seedString.charCodeAt(i) + ((hash << 5) - hash)
      }

      let color = '#'
      for (let j = 0; j < 3; j++) {
        const value = ((hash >> (j * 8)) & 0xFF) % 128 + 127
        color += ('00' + value.toString(16)).substr(-2)
      }

      return color
    },

    removeDuplicates (array) {
      array.forEach(item => {
        const uniqueData = []

        item.data.forEach(dataItem => {
          if (!uniqueData.some(uniqueItem => uniqueItem.x === dataItem.x && uniqueItem.y === dataItem.y)) {
            uniqueData.push(dataItem)
          }
        })

        item.data = uniqueData
      })

      return array
    },

    getChartData () {
      const groupedByPa = _.groupBy(this.sortedResults, 'practice_area_name')
      const allLocations = new Set()

      const seriesData = Object.entries(groupedByPa).map(([practiceAreaName, results]) => {
        // const obj = {}
        let color

        switch (practiceAreaName) {
          case 'personal injury':
            color = '#f20b47'
            break
          case 'family law':
            color = '#06d1b9'
            break
          case 'criminal law':
            color = '#ab1aee'
            break
          case 'employment law':
            color = '#2c6cfd'
            break
          default:
            color = this.getRandomColor(practiceAreaName)
        }

        const formattedData = results.map(res => {
          allLocations.add(res.location)
          return { x: res.location, y: res.arc_score }
        })

        return {
          name: practiceAreaName,
          data: formattedData,
          color: color
        }
      })

      const categories = Array.from(allLocations).sort()

      const adjustedSeriesData = seriesData.map(series => {
        return {
          ...series,
          data: series.data.map(dataItem => {
            return { x: categories.indexOf(dataItem.x), y: dataItem.y }
          })
        }
      })

      return {
        seriesData: this.removeDuplicates(adjustedSeriesData),
        categories: categories
      }
    },

    getWebsitesInOutreach (created, outreachCompanies) {
      const webResults = []

      if (created) {
        webResults.push(outreachCompanies)
      } else {
        outreachCompanies.forEach(item => {
          webResults.push(item.websites)
        })
      }
      this.websitesInOutreach = webResults.flat().map(item => {
        const regexItem = item.replace(/https:\/\/www.|http:\/\/www.|https:\/\/|http:\/\/|/, '')
        const newStr = regexItem.replace('/', '')
        return newStr
      })
    },

    debounceLocationName: _.debounce(function (val) {
      if (val.length > 2) {
        const cleanedVal = val.replace(/, /g, ',')
        this.getLocations(cleanedVal)
      } else {
        this.filteredLocations = []
      }
    }, 200),

    getLocations (name) {
      this.filteredLocations = this.searchUsLocationList(name).map(location => {
        return location.replace(/,/g, ', ')
      })
    },

    groupBy (array, f) {
      var groups = {}
      array.forEach(function (o) {
        var group = JSON.stringify(f(o))
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      return Object.keys(groups).map(function (group) {
        return groups[group]
      })
    },

    calculateAvgScore () {
      if (this.tableData.results && this.tableData.results_excl_from_arc_score_calc) {
        var resultsExcl = this.tableData.results_excl_from_arc_score_calc
        const arcScoreList = []

        this.sortedResults.forEach(res => {
          if (!resultsExcl.includes(res.result_id)) {
            arcScoreList.push(res)
          }
        })

        var duplicates = this.groupBy(arcScoreList, function (item) {
          return [item.practice_area_name, item.location]
        }).sort(function (a, b) {
          return new Date(b.last_checked_at) - new Date(a.last_checked_at)
        })

        var uniquePairs = []

        duplicates.forEach(obj => {
          uniquePairs.push(obj[0])
        })

        uniquePairs.forEach(pair => {
          var result = this.tableData.results.find(x => x.result_id === pair.result_id)
          result.included_in_avg = true
        })

        this.tableData.results.forEach(result => {
          if (!uniquePairs.find(r => r.result_id === result.result_id)) {
            this.$delete(result, 'included_in_avg')
          }
        })

        var arcScoreSum = uniquePairs.reduce((acc, o) => {
          return acc + o.arc_score
        }, 0)

        var arcScoreAvg = (arcScoreSum / uniquePairs.length).toFixed(2)

        return arcScoreAvg
      }
    },

    checkDuplicates () {
      const objectList = []
      this.sortedResults.forEach(result => {
        objectList.push({
          location: result.location,
          practiceArea: result.practice_area_name,
          resultId: result.result_id
        })
      })

      const keys = ['location', 'practiceArea']
      var counterObj = {}
      let keyForCounterObj
      objectList.forEach(obj => {
        keyForCounterObj = ''
        keys.forEach(key => {
          keyForCounterObj += String(obj[key])
        })
        if (counterObj[keyForCounterObj]) {
          counterObj[keyForCounterObj].times++
        } else {
          counterObj[keyForCounterObj] = {
            ...obj,
            times: 1
          }
        }
      })

      const newArr = []
      const counterObjKeys = Object.keys(counterObj)
      counterObjKeys.forEach(key => {
        newArr.push(counterObj[key])
      })

      const newArrIds = newArr.map(a => a.resultId)
      const objectListIds = objectList.map(a => a.resultId)

      const excluded = objectListIds.filter(item => {
        return !newArrIds.includes(item)
      })

      return excluded
    },

    getArcRank () {
      if (this.sort.key === 'last_checked_at' && !this.sort.isAsc) {
        this.sortedResults.forEach((result, i) => {
          result.number = i + 1
        })
      }
    },

    getSiteHistory (domain) {
      if (domain) {
        this.loading = true
        this.clearFilters()
        const strippedDomain = domain.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '')
        this.$router.replace({
          path: `/site-history?domain=${strippedDomain}`,
          _randomKey: `${Date.now()}`
        }).catch(() => {})
        this.$arc.get(`domains/${strippedDomain}/arc_scores?skip_results_excl_from_arc_score_calc=1`)
          .then(res => {
            this.$store.dispatch('table/setTableData', res.data)
            this.tableData.results = res.data.arc_score_history
            this.getArcRank()
            this.loading = false
          })
          .catch(err => {
            console.error(err)
            this.loading = false
          })
      } else {
        this.loading = false
      }
    },

    isIncluded (resultId) {
      return !this.tableData.results_excl_from_arc_score_calc?.includes(resultId)
    },

    excludeFromArc (domain, resultId) {
      this.$arc.post(`domains/${domain}/results_excl_from_arc_score_calc/${resultId}`)
        .then(res => {
          this.tableData.results_excl_from_arc_score_calc = res.data.results_excl_from_arc_score_calc
          var exclResult = this.tableData.results.find(obj => obj.result_id === resultId)
          this.$delete(exclResult, 'included_in_avg')
          this.$forceUpdate()
        })
        .catch(err => {
          console.error(err)
        })
    },

    includeInArc (domain, resultId) {
      this.$arc.delete(`domains/${domain}/results_excl_from_arc_score_calc/${resultId}`)
        .then(res => {
          this.tableData.results_excl_from_arc_score_calc = res.data.results_excl_from_arc_score_calc
          this.$forceUpdate()
        })
        .catch(err => {
          console.error(err)
        })
    },

    includeOrExclude (domain, resultId) {
      if (this.isIncluded(resultId)) {
        this.excludeFromArc(domain, resultId)
        this.calculateAvgScore()
      } else {
        this.includeInArc(domain, resultId)
        this.calculateAvgScore()
      }
    },

    sortedClass (key) {
      return this.sort.key === key
        ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}`
        : ''
    },

    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false
      this.sort.key = key
    },

    applyFilters () {
      this.filters = {
        locationFilter: this.form.location,
        practiceAreaFilter: this.form.practiceArea,
        tierFilter: this.form.tier
      }
    },

    clearFilters () {
      const that = this
      that.$refs.locationDropdown.selected = ''
      that.$refs.tierDropdown.selected = ''
      that.$refs.practiceAreaDropdown.selected = ''
      this.form.practiceArea = ''
      this.form.tier = ''
      this.form.location = ''
      this.filters = {
        locationFilter: '',
        practiceAreaFilter: '',
        tierFilter: ''
      }
    }
  }
}
</script>

<style lang="scss">
.history {
  .filters {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 30px;
    strong {
      margin-bottom: 1rem;
    }
    > div {
      flex-basis: calc((100% / 3) - 85px);
      &.flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        label {
          font-weight: 600;
          margin-bottom: 10px;
          display: block;
          width: 100%;
        }
        .input-container {
          flex-basis: calc((100% / 2) - 7px);
        }
      }
    }
    strong {
      margin-right: 15px;
    }
  }
  h2 {
    text-align: center;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    position: relative;
    .domain-container {
      flex-grow: 1;
      .btn-group {
        margin-top: 15px;
        button.is-small {
          margin: 0;
        }
      }
    }
    h2 {
      a {
        color: inherit;
      }
      svg {
        font-size: 14px;
        display: inline-block;
        margin-bottom: 10px;
      }
    }
    span.right-aligned {
      font-weight: 700;
      font-size: 25px;
      position: absolute;
      right: 0;
      top: 0;
      transform: none;
    }
  }
}
</style>
